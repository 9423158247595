import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import SearchIcon from '@mui/icons-material/Search';
import {Autocomplete} from "@mui/material";
import {createFilterOptions, InputAdornment} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import {SnackbarFormattedError} from "../../../../../helpers/snackbar";
import {shopService} from "../../store/services";

const ProductsAutocomplete = ({
  onSelect,
  autoFocus,
  onChange,
  country,
  variant = "filled",
  label,
  withPrice,
  withoutRestriction,
  isPrescription = false,
  sx
}) => {

  const {t, i18n} = useTranslation();
  const [searchAutocompleteOpen, setSearchAutocompleteOpen] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (search.length === 2) {
      setSearchLoading(true);
      shopService.searchProducts(i18n.language, country, search, withoutRestriction, isPrescription)
        .then((products) => {
          const searchLower = search.toLowerCase();
          const privilegedBrandId = "083632d8-fa53-4a21-8a95-c46b3a4c4e88";

          const getScore = (product) => {
            let score = 0;
            // --- Vérifie la marque privilégiée ---
            if (product.brand?.id === privilegedBrandId) {
              // Bonus "raisonnable" pour la marque
              score += 30;
            }

            // --- Vérifie la référence ---
            const ref = product.reference?.toLowerCase() || "";
            if (ref.startsWith(searchLower)) {
              score += 140;
            }
            if (ref.includes(searchLower)) {
              score += 80;
            }

            // --- Vérifie searchText ---
            const st = product.searchText?.toLowerCase() || "";
            if (st.includes(searchLower)) {
              score += 50;
            }

            // --- Vérifie la composition ---
            const comp = product.detail?.composition?.toLowerCase() || "";
            if (comp.includes(searchLower)) {
              score += 20;
            }
            return score;
          };

          // On trie par score décroissant
          products.sort((a, b) => getScore(b) - getScore(a));

          setProducts(products);
          setSearchLoading(false);
        })
        .catch(e => {
          console.log(e);
          SnackbarFormattedError(e.response.data.error);
          setSearchLoading(false);
        });
    }
  }, [search]);

  const handleSearchChange = (e) => {

    setSearch(e.target.value);
    onChange && onChange(e);
  };

  const handleSelectProduct = (e, value) => {

    if (value) {
      onSelect && onSelect(value);
    }
  };

  const filterOptions = createFilterOptions({
    stringify: option => `${option.reference} ${option.searchText} ${option.detail.composition} ${option.keywords?.map(keyword => keyword.name)}`
  });

  return (
    <Autocomplete
      id="asynchronous-search"
      fullWidth
      open={searchAutocompleteOpen}
      filterOptions={filterOptions}
      onOpen={() => {
        setSearchAutocompleteOpen(true);
      }}
      onClose={() => {
        setSearchAutocompleteOpen(false);
      }}
      onChange={handleSelectProduct}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => ""}
      options={products}
      noOptionsText={t('searchByNameOrComposition')}
      loading={searchLoading}
      sx={{...sx}}
      renderOption={(props, option) => (
        //li must be ordered by last product selected first
        <li {...props} key={option?.id}>
          <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={2}>
              <Typography align={"center"}>
                <img
                  width={50}
                  height={50}
                  src={option.image[200]}
                  alt={option.reference}
                />
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Grid container>
                <Grid item xs={12}>
                  {option.reference} {withPrice ? ` - ${option[`price`]} ${option.currency}` : ''}
                </Grid>
                <Grid item xs={12} style={{height: 28, overflow: "hidden"}}>
                  {option.categories.map((category) => (
                    <Typography key={category.id} variant={"caption"} color={"textSecondary"}>
                      {category.name}
                    </Typography>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus={autoFocus}
          fullWidth
          label={label}
          placeholder={t("searchProduct")}
          variant={variant}
          onChange={handleSearchChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end" sx={{margin: '0px -16px 5px 0px', color: '#7C909B'}}>
                {searchLoading ? <CircularProgress color="inherit" size={20}/> : null}
                <SearchIcon/>
              </InputAdornment>
            )
          }}
        />
      )}
    />
  );
};
export default ProductsAutocomplete;
