import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";

import {getProductPriceWithTaxes} from "../../../../../helpers/utils.js";
import {userSelector} from "../../../../Authentication/store/selectors.js";

import OrderItem from "./OrderItem.js";

const OrderListItems = ({className, items, handleRemoveProduct, setProductQuantity}) => {

  const {t} = useTranslation();
  const [totalPrice, setTotalPrice] = useState(0);
  const [currency, setCurrency] = useState('');
  const user = useSelector(userSelector);

  useEffect(() => {
    let amount = 0;
    Object.keys(items).map(key => {
      const userWithDiscount = user && {...user, userDiscount: user.proDiscountToClient};
      const priceWithDiscount = getProductPriceWithTaxes(items[key], userWithDiscount, user?.shippingAddress, items[key].quantity, true);
      amount += priceWithDiscount;
      setCurrency(items[key].currency);
    });
    setTotalPrice(amount);
  }, [items]);

  return (
    <List subheader={<Typography variant={'h6'}>{t('Total')} : {totalPrice.toFixed(2)} {currency} TTC</Typography>} className={className}>
      {Object.keys(items).map(key => (
        <React.Fragment key={key}>
          <OrderItem item={items[key]} setProductQuantity={setProductQuantity} handleRemoveProduct={handleRemoveProduct} user={user}/>
          {items.length > 1 && (
            <Divider/>
          )}
        </React.Fragment>
      ))}
    </List>
  );
};
export default OrderListItems;
