import React from "react";
import {Trans, useTranslation} from "react-i18next";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import ListItem from "@mui/material/ListItem";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import ItemPrice from "../../../Prescriptions/components/AddCustomerPrescription/ItemPrice.js";

const OrderItem = ({item, setProductQuantity, user = {}}) => {

  const {t} = useTranslation();

  const handleChangeItemQuantity = (item, quantity) => {

    setProductQuantity(item, quantity);
  };

  const quantityOptions = () => {

    let quantityOptions = [];
    for (let i = 0; i <= 100; ++i) {
      quantityOptions.push(
        <MenuItem key={i} value={i}>{i}</MenuItem>
      );
    }
    return quantityOptions;
  };
  return (
    <ListItem>
      <Grid container justify={"space-between"} alignItems={"center"}>
        <Grid item>
          <Grid container alignItems={"center"} spacing={2}>
            <Grid item>
              <img
                width={80}
                height={80}
                src={item.image[200]}
                alt={item.reference}
              />
            </Grid>
            <Grid item zeroMinWidth>
              <FormControl
                variant={"outlined"}
              >
                <InputLabel>{t('qtyCart')}</InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  name={"quantity"}
                  value={item.quantity}
                  onChange={(e) => handleChangeItemQuantity(item, e.target.value)}
                  label={t('qtyCart')}
                >
                  {quantityOptions()}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" color={"inherit"}>
                {item.reference}
              </Typography>
            </Grid>
            <Grid item>
              <ItemPrice
                item={
                  item && {
                    ...item,
                    discountPercent: user && user?.proDiscountToClient ? Math.min(item.discountMaxPercentage, user.proDiscountToClient?.percentage) : 0
                  }
                }
                quantity={item.quantity}
                component={"span"}
                user={user && {...user, userDiscount: user.proDiscountToClient}}
                withDiscount
                sx={{
                  fontFamily: 'Montserrat',
                  fontSize: {xs: '1.4rem', sm: '1.4rem', md: '1.4rem', lg: '1.4rem', xl: '1.4rem', xxl: '1.4rem'},
                  fontWeight: 'bold',
                }}
              />
            </Grid>
            <Grid item>
              <ItemPrice
                item={
                  item && {
                    ...item,
                    discountPercent: user && user?.proDiscountToClient ? Math.min(item.discountMaxPercentage, user.proDiscountToClient?.percentage) : 0
                  }
                }
                quantity={item.quantity}
                component={"span"}
                user={user && {...user, userDiscount: user.proDiscountToClient}}
                total
                sx={{
                  fontFamily: 'Montserrat',
                  fontSize: {xs: '1.4rem', sm: '1.4rem', md: '1.4rem', lg: '1.4rem', xl: '1.4rem', xxl: '1.4rem'},
                  fontWeight: 'bold',
                }}
              />
            </Grid>
            <Grid item>
              {(user && user?.proDiscountToClient) && (
                <Typography
                  sx={{
                    height: '19px',
                    minWidth: '22px',
                    lineHeight: '0',
                    borderRadius: '6px',
                    cursor: 'default',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                    display: 'inline-flex',
                    justifyContent: 'center',
                    padding: '0px 8px',
                    fontSize: '0.75rem',
                    fontWeight: 700,
                    textTransform: 'uppercase',
                    color: 'rgb(34, 154, 22)',
                    backgroundColor: 'rgba(84, 214, 44, 0.16)',
                    position: 'absolute',
                    top: '12px',
                    left: '175px'
                  }}>
                  <Trans i18nKey={"discount"}>Discount</Trans>: {Math.min(item.discountMaxPercentage, user.proDiscountToClient?.percentage)} %
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item zeroMinWidth>
          <IconButton color="primary" onClick={() => handleChangeItemQuantity(item, 0)}>
            <DeleteOutlineOutlinedIcon/>
          </IconButton>
        </Grid>
      </Grid>

    </ListItem>
  );
};
export default OrderItem;
