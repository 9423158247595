export default  {
  "You have successfully subscribed to our newsletter": "Vous vous êtes inscrit avec succès à notre newsletter",
  "emailLabel": "Adresse e-mail",
  "errorMessageOrderPriceMin": "Le montant minimum d'une commande (hors commandes gratuites) est de 0,50€",
  "mister": "Mr.",
  "miss": "Mme",
  "noGender": "Non genré",
  "genderLabel": "Titre",
  "createPrescription": "Faire une prescription",
  "forbiddenItemsFromCheckout": "Produit.s indisponible.s à la livraison dans le pays sélectionné",
  "therapNameLabel": "Nom et prénom de votre thérapeute, Professionel de la santé qui vous a conseillé nos produits *",
  "codeLabel1": "J'ai un code ?",
  "codeLabel2": "Votre code se trouve sur votre bon de commande en haut à droite",
  "codeLabel3": "Je n'ai pas de code ?",
  "codeLabel4": "Entrez le code provisoire",
  "codeLabel5": "pour créer votre compte.",
  "codeLabel6": "Un conseiller vous contactera prochainement pour mettre à jour votre fiche.",
  "unitLabel": "GELULES",
  "weightLabel": "POIDS NET",
  "Favorites": "Favoris",
  "fullBrandNameNutri-logics": "Nutri-logics - Accompagnement global de la santé",
  "NLGiftCard": "Carte cadeau Nutri-Logics ",
  "homeMetaDescription": "Nutri Logics est un groupement de professionnels de la santé qui a érigé ses fondations sur le respect, la bienveillance, le partage et l'excellence.",
  "homeTitle": "Nutri Logics",
  "shopLinkTitle": "Accéder à la boutique",
  "shopLinkDescription": "Commandez vos compléments alimentaires dans notre boutique en ligne. Les commandes sont préparées le jour même.",
  "becomePartner": "Devenir partenaire",
  "becomePartnerLinkDescription": "Vous désirez devenir un partenaire Nutri-logics ? Retrouvez tous les détails ici.",
  "aboutLinkTitle": "Nutri-logics, le MAG",
  "aboutLinkDescription": "Découvrez Nutri-logics à travers sa communauté, ses bons plans et ses services en matière de compléments alimentaires.",
  "consultation": "Consultation",
  "scientificDoc": "Documents scientifiques",
  "nutritionConsultantP1": "Nutri-logics est une société Luxembourgeoise spécialisée dans la production et dans la distribution de compléments alimentaires de haute qualité, en collaboration avec ESE group, engagé dans la recherche scientifique dans le domaine de la nutrition.",
  "nutritionConsultantP2": "Grâce à un réseau performant de conseillers en nutrition, de délégués et de consultants spécialisés dans la distribution de ces compléments, Nutri-logics est en étroite collaboration avec des médecins, ostéopathes, kinésithérapeutes et hypnothérapeutes dans une perspective d'approche globale de la santé.",
  "nutritionConsultantSearchTitle": "Vous désirez une consultation chez un conseiller en nutrition près de chez vous ?",
  "globalObservationTitle": "Notre constat",
  "globalObservationP1": "Aujourd'hui, on meurt davantage de manger trop ou mal que de faim!",
  "globalObservationP2": "D'après l'OMS (Organisation mondiale de la santé), la dénutrition est responsable de 9,1 millions de décès par an alors que la malbouffe provoque 11 millions de morts!",
  "globalObservationP3": "Une personne sur vingt souffre de diabète soit 350 millions de personnes.",
  "globalObservationP4": "Sur la population mondiale, environ 3 milliards de personnes sont en surpoids,  42 millions d'enfants souffrent d'obésité…",
  "globalObservationLink": "En savoir plus ...",
  "globalObservationConclusion": "À travers le réseau Nutri-logics, nous souhaitons que chacun ait une prise de conscience sur l'importance de la qualité de notre alimentation. <1/> Nous sommes convaincus que chacun peut saisir l'opportunité de poser des choix positifs pour une assiette à la fois goûteuse et riche en nutriments essentiels à notre développement physique et psychique. <3/> Il est temps de mettre un terme à la surexploitation des sols, à l'appauvrissement de la teneur en minéraux, en vitamines,… des légumes et fruits cultivés de manière intensive.",
  "foodSupplementsTitle": "Les compléments alimentaires Nutri-logics",
  "foodSupplementsP1": "Nos clients bénéficient du meilleur de la nature! <1/>La qualité de nos partenaires (producteurs, laboratoires) nous permet d'être à la pointe du savoir-faire de nos produits.",
  "foodSupplementsP3": "Notre collaboration avec le département de recherche scientifique de ESE GROUP nous permet de sélectionner avec soin les composants des compléments qui sont élaborés dans notre laboratoire. <1/>ESE Group innove sans cesse dans le milieu de la nutrition et de la recherche nutritionnelle.",
  "foodSupplementsP5": "L'objectif est d'élaborer des produits en se basant sur les données scientifiques les plus récentes \"EVIDENCE BASED MEDECINE\" (EBM ou Médecine fondée sur les faits)",
  "foodSupplementsP6": "Nos gélules sont conçues sans excipient, un gage de qualité. Les ingrédients sont issus de différents laboratoires de renommée internationale tant pour leur qualité que pour leur innovation. La plupart des compléments alimentaires que nous proposons sont labélisés.",
  "foodSupplementsP7": "Nos produits sont en constante amélioration, en fonction des découvertes pertinentes les plus récentes dans le domaine médical et dans le domaine de la santé.",
  "philosophyTitle": "Nos engagements",
  "philosophyP1": "Une qualité de produits irréprochable!",
  "philosophyP2": "Rapport qualité-prix imbattable",
  "philosophyP3": "Un engagement fort pour un monde plus respectueux des ressources",
  "philosophyP3Li1": "Emballages 100% biodégradables.",
  "philosophyP3Li2": "Production tendant vers le zéro déchet.",
  "philosophyP3Li3": "Maîtrise de l'empreinte carbone de notre filière de production grâce à la plantation d'arbres (chaque arbre planté représente une absorption de  +/- 22kg de CO2 /an).",
  "philosophyP4": "Une vision solidaire du monde",
  "philosophyP4Li1": "Participer à la lutte contre la malnutrition infantile dans les pays pauvres grâce à la plantation d'arbres de Moringa dont la feuille est riche en protéines végétales.",
  "shop": "Boutique",
  "events": "Evènements",
  "addressSearch": "Adresse",
  "addressAlias": "Alias de l'adresse (facultatif)",
  "addressAliasRequired": "Alias de l'adresse",
  "postalBox": "N° de boite (facultatif)",
  "addressSearchPlaceholder": "Ajoutez une ville, un lieu, une adresse",
  "profilingTitle": "Profilage",
  "proSpace": "Espace pro",
  "charityInfo": "Nutri-logics soutient l'association {{name}}",
  "charityInfoAmount": "{{amount}} € déjà collecté",
  "environmentalFriendly": "Éco-responsable",
  "treesCounterDescription": "Chaque commande contribue à la plantation d’arbres de Moringa au Sénégal.",
  "treesCounterInfo": "Grâce à vos commandes, Nutri-logics a planté {{amount}} arbres.",
  "treesContributionInfo": "Chaque commande contribue à la plantation d’arbres de Moringa au Sénégal pour participer à la réduction de l’empreinte carbone. <1/>(Un arbre = Réduction de 22kilos de CO2 / an)</1>",
  "treeProperties": "Le moringa est également un arbre intelligent qui possède des propriétés nutritionnelles et thérapeutiques.",
  "loading": "Chargement",
  "learnMore": "En savoir plus",

  "followUsInstagram": "Pour ne rien rater des actus Nutri-logics, suivez-nous sur",

  //CAREER OPPORTUNITY
  "networkOpportunityMetaDescription": "Devenir partenaire Nutri-logics, certification de conseiller en nutrition, réorientation professionnelle",
  "founderCEONutri-logics": "Fondateur et CEO Nutri-logics",
  "partnerUpYoYourAmbitions": "Un partenaire à la hauteur de vos ambitions.",
  "networkOpportunityH1": "Une carrière pour des pros",
  "networkOpportunityP1": "Le système de rémunération du délégué dépend davantage d'une gestion performante de son portefeuille que de l'acquisition sans cesse de nouveaux clients.",
  "networkOpportunityP2": "Le secteur de la santé nécessite une formation sérieuse en physiologie et anatomie. Il est donc fondamental d'avoir sur le terrain des gens extrêmement bien formés sur la matière.",
  "networkOpportunityH2": "Activez votre désir de réorienter votre carrière en choisissant un job qui ait du sens!",
  "networkOpportunityP3": "Le partenariat Nutri-logics offre 4 voies de collaborations:",
  "networkOpportunityP4": "La première est orientée vers le conseil et l'accompagnement en devenant conseiller en nutrition certifié par l'Académie Européenne de Nutrition. Cette voie est une opportunité vers un vrai métier.",
  "networkOpportunityP5": "La seconde, offre des perspectives de développement managérial tel que la direction d'une équipe de délégués spécialisés dans les compléments alimentaires. Cette voie vous demandera de développer des compétences en coaching et management avec, à la clé, la possibilité d'ouvrir, en collaboration avec la société-mère, un centre Nutri-logics.",
  "networkOpportunityP51": "La troisième voie est orientée vers une spécialisation dans la vente avec un profil, appelé «Vente directe». L'objectif de ce parcours est de créer des partenariats avec des institutions de santé, de bien-être, de sport, ...",
  "networkOpportunityP52": "La quatrième est dernière voie concerne les institutions et distributeurs des compléments Nutri-logics.",
  "networkOpportunityP6": "Quatres voies \"win-win\", dans une ambiance positive de travail, où l'humain est une composante essentielle.",
  "networkOpportunityH3": "L'accompagnement des candidats délégués.",
  "networkOpportunityP7": "La qualité de l'encadrement professionnel d'Nutri-logics est primordiale pour conseiller au mieux nos clients. Chaque délégué est soigneusement sélectionné et formé en continu jusqu'à l'obtention d'une certification de  Conseiller en nutrition, garantie d'une compétence aiguisée, d'un service irréprochable et d'un conseil optimal.",
  "networkOpportunityH4": "Comment devenir un délégué Nutri-logics ?",
  "networkOpportunityP8": "La première étape pour devenir un délégué Nutri-logics est de réaliser le test profilage qui se trouve sur notre site. Ensuite, le futur délégué sera obligatoirement encadré par un de nos consultants. Il est impossible d'avoir accès au réseau sans passer par cette étape qui garantit une qualité de formation.",
  "networkOpportunityP9": "Notre partenariat avec l'Académie Européenne de Nutrition, permet à nos délégués et consultants, non certifiés en nutrithérapie, de recevoir une formation pointue qui débouche sur un vrai métier et une certification reconnue.",
  "networkOpportunityP10": "La compétence du personnel ainsi formé est un élément fondamental pour la montée en puissance d'Nutri-logics.",
  "profilingGDPR": "Nous sommes soumis aux règles rgpd et nous tenons à les respecter à travers ce questionnaire.",
  "dataWarning": "Nous nous engageons à traiter vos informations dans la plus grande discrétion.",
  "strengthsTitle": "Les atouts pour nos nutrithérapeutes",
  "strengthsP1": "Une formation certifiante par l' Académie Européenne de Nutrition",
  "strengthsP2": "Une formation continue avec des médecins, des scientifiques, des nutritionnistes de renom.",
  "strengthsP3": "Un encadrement par une structure internationale.",
  "strengthsP4": "Un apport patientel",
  "strengthsP5": "Des produits \"Haut de gamme\"",
  "strengthsP6": "Une collaboration avec le staff des consultants et délégués.",
  "strengthsP7": "Possibilité de profiter d'un espace dans un centre Nutri-logics.",
  "strengthsP8": "Collaboration avec des kinésithérapeutes, ostéopates, hypnothérapeutes.",
  "strengthsP9": "Référencement comme nutrithérapeute dans une application spécialisée.",
  "strengthsP10": "Accès à de nouvelles technologies santé.",
  "strengthsP11": "Outil innovant.",
  "perspectivesTitle": "Les perspectives pour le réseau",
  "perspectivesP1": "Obtenir une certification reconnue en Europe",
  "perspectivesP2": "Créer un revenu récurrent",
  "perspectivesP3": "Exercer un travail porteur de sens, avec comme valeurs fortes, l'humain et l'écologie",
  "perspectivesP4": "Bénéficier de formations en nutrithérapie, anatomie, biologie mais aussi en nouvelles technologies et techniques de vente et de développement personnel (prospection, coaching, recrutement, développement du réseau, management, communication, gestion, motivation, inspiration, leadership, stratégie…)",
  "perspectivesP5": "Des voyages, des séminaires, des colloques.",
  "perspectivesP6": "Développer un centre Nutri-logics.",
  "perspectivesP7": "S'installer comme conseiller en nutrition et profiter d'une structure d'encadrement.",
  "perspectivesP8": "Réaliser des actions humanitaires en lien avec le bien-être.",
  "perspectivesP9": "Être compétent dans l'éducation et l'information alimentaires.",
  "perspectivesP10": "Contribuer à diminuer notre impact carbone.",
  "perspectivesP11": "Organisation du travail, bon sens, passion, force du groupe.",
  "perspectivesP12": "Accompagnement respectueux du client",
  "perspectivesP13": "Rémunérations valorisant l'investissement personnel à construire son réseau",
  "profilingTestButton": "Accéder au test de profilage",
  "profilingTestButtonMin": "Profilage",
  "academyTitle": "Notre école de formation",
  "academyP1": "<0>L'Académie Européenne de Nutrition</0>, est une toute nouvelle entité de l'académie européenne de coaching qui par son statut et sa compétence, nous permet d'offrir une certification reconnue en Europe.",
  "academyP2": "Le budget total de la formation (1.300€) est particulièrement attractif, vu la qualité des intervenants.",
  "academyP3": "Les formateurs sont tous des professionnels (médecin pharmacien biochimiste) qualifiés et expérimentés dans leur domaine. Nos délégués profitent d'une formation de qualité mise à jour régulièrement.",
  "academyP4": "Les conseillers Nutri-logics se doivent d'être performants et précis dans l'information apportée aux clients.",
  "academyP5": "La formation a ceci de magique, qu'une fois donnée, personne ne peut vous la reprendre.",
  "certificationDetails": "Voir les détails de la certification",
  "noConsultantFound": "",

  //SHOP
  "missingShippingAddress": "Vous devez choisir une adresse de livraison",
  "delivery": "Livraison",
  "click&collect": "Click & collect",
  "addAddress": "Ajouter une adresse",
  "atHome": "A domicile",
  "to": "À",
  "changeAddress": "Changer d'adresse",
  "shopMetaDescription": "Découvrez les compléments alimentaires Nutri-logics de grande qualité. Trouvez le pack Nutri-logics qui vous convient : pack détox Nutri-logics, pack immunité, pack gestion du poids, pack gestion du sommeil, pack digestion, pack business, pack intestin, pack douleur ... Nos gélules sont conçues sans excipient, un gage de qualité.",
  "eventMetaDescription": "Découvrez les évènements Nutri-logics. Trouvez les évènements Nutri-logics près de chez vous. Inscrivez-vous à un évènement Nutri-logics.",
  "shopTitle": "Boutique Nutri-logics",
  "eventTitle": "Nutri-logics Evènements",
  "shopProTitle": "Boutique PRO Nutri-logics",
  "backToShop": "Retour à la boutique",
  "allProducts": "Tous les produits",
  "packetDelivery": "Distributeur de colis",
  "deliveryCountry": "Pays de livraison",
  "productSearchByText": "Rechercher un produit ...",
  "productSearchByTextMin": "Rechercher ...",
  "moreCategories": "Plus de catégories",
  "addToCart": "Ajouter au panier",
  "subscription": "Inscription",
  "removeItemFromCart": "Retirer l'article du panier",
  "productNoLongerInShop": "Ce produit revient bientôt",
  "searchConsultantToAccessShop": "Pour accéder a la boutique vous devez séléctionner votre conseiller en nutrition. Si vous êtes déjà client Nutri-logics, veuillez simplement vous connecter.",
  "searchTitle": "Recherche \"{{search}}\"",
  "newSearch": "Nouvelle recherche",
  "moreProductsButton": "Montrer plus de produits",
  "proShop": "Boutique pro",
  "proPrices": "Prix PRO",
  "invalidLink": "Lien invalide",
  "invalidDirectLinkDescription": "Le lien que vous avez suivi est invalide ou inactif actuellement. Vous pourrez retrouver ou choisir votre conseiller en nutrition plus tard sur la boutique.",
  "Nutri-logicsWishesPleasantVisit": "L'équipe Nutri-logics vous souhaite une bonne visite sur notre site.",
  "continueToTheSiteButton": "Continuer vers le site",
  "catalog": "Nos produits",
  "catalogMetaDescription": "Découvrez les compléments alimentaires Nutri-logics de grande qualité. Trouvez le pack Nutri-logics qui vous convient : pack détox Nutri-logics, pack immunité, pack gestion du poids, pack gestion du sommeil, pack digestion, pack business, pack intestin, pack douleur ... Nos gélules sont conçues sans excipient, un gage de qualité.",
  "yourNLAccount": "Votre compte Nutri-logics",
  "useFromWallet": "Utiliser {{amount}}{{currency}} de votre compte Nutri-logics",
  "agsCredit": "Credit Nutri-logics",
  "Nutri-logicsGiftCard": "Carte cadeau Nutri-logics",
  "selectTheGiftCard": "Sélectionnez votre carte cadeau",
  "enterTheGiftCard": "Entrez le code de votre carte cadeau",
  "giftCardSent": "Carte cadeau envoyée avec succès à votre client",
  "giftCardAddedDescription": "La carte cadeau a bien été prise en compte.",
  "buyGiftCardForCustomerDescription": "Acheter une carte cadeau ? Une fois le paiement terminé, les cartes cadeaux s'afficheront ici et vous pourrez les envoyer à vos clients.",
  "leftToPay": "Reste à payer",
  "buyInShop": "Acheter dans la boutique",
  "shareThisOrder": "Partager cette commande",
  "outOfStockWarning": "Ce produit est actuellement en rupture de stock.\n" +
      "\n" +
      "Souhaitez-vous l’ajouter à votre panier d’achat ?\n" +
      "Sachez que le produit vous sera automatiquement livré dès réception SANS frais supplémentaires ",
  "reviews": "Avis",
  "reviewLabel": "avis",
  "reviewLabel_plural": "avis",
  "calculatedFromReview": "Calculé à partir de {{count}} avis client",
  "calculatedFromReview_plural": "Calculé à partir de {{count}} avis clients",
  "noReviewsYet": "Il n'y a pas encore d'avis pour ce produit",
  "youNeedToRateTheProduct": "Vous devez évaluer le produit",
  "reviewSaved": "Avis sauvegardé",
  "writeReviewAbout": "(Facultatif) Donnez votre avis sur ce produit...",
  "howWasTheProduct": "Qu'avez vous pensé du produit \"{{name}}\" ?",
  "verySatisfied": "Très satisfait",
  "satisfied": "Satisfait",
  "neutral": "Neutre",
  "dissatisfied": "Déçu",
  "veryDissatisfied": "Très déçu",
  "showOriginal": "Montrer l'original",
  "translate": "Traduire",
  "searchByNameOrComposition": "Recherchez par nom ou composition",

  //SHOP ITEM
  "themes": "Themes",
  "labos": "Labos",
  "categories": "Categories",

  "limited": "Limité",
  "limitedCommissions": "Commissions limitées",
  "discount": "Réduction",
  "card": "Fiche",
  "unsupportedDefaultShippingAddress": "Votre adresse par défaut se situe dans un pays non supporté.",
  "definition": "Définition",
  "usage": "Utilisation",
  "recommendations": "Recommandations",
  "indications": "Indications",
  "indicationsPro": "Indications (Pro)",
  "shortIndications": "Indications courtes",
  "shortIndicationsPro": "Indications courtes (Pro)",
  "conservation": "Conservation et précautions d’emploi",
  "composition": "Composition",
  "video": "Vidéo",
  "boxContains": "La boite contient",
  "proInfo": "Info pro",
  "recommendedDosage": "Dosage recommandé",
  "contraindications": "Contre-indications",
  "guaranteedWithout": "Garanti sans",
  "precautionsForUse": "Précautions d'emploi",
  "excipients": "Excipients",
  "ingredients": "Ingrédients",
  "usageTips": "Conseils d'utilisation",
  "ml": '{{count}} ml',
  "mlMin": '{{count}} ml',
  "g": '{{count}} g',
  "gMin": '{{count}} g',
  "capsule": "{{count}} gélule",
  "capsuleMin": "{{count}} Caps",
  "capsule_plural": "{{count}} gélules",
  "pill": "{{count}} comprimé",
  "pillMin": "{{count}} Comp",
  "pill_plural": "{{count}} comprimés",
  "stick": "{{count}} stick",
  "stickMin": "{{count}} stic",
  "sticks_plural": "{{count}} sticks",

  //SHOPPING CART
  "shoppingCart": "Panier",
  "qtyCart": "Qté",
  "proFee": "Frais achat pro",
  "subTotal": "Sous total",
  "taxes": "Taxes",
  "totalPrice": "Prix total",
  "cartContinueShoppingButton": "Continuer mes achats",
  "cartOrderButton": "Valider mon panier",
  "shippingCalculationOnCheckout": "Le prix de livraison sera calculé lors du paiement",

  //SHOP CHECKOUT
  "checkoutTitle": "Checkout",
  "billedTo": "Facturé à",
  "NLGiftCardsAndCoupons": "Cartes cadeaux Nutri-Logics et bons d'achats",
  "coupons": "Bons d'achats",
  "coupon": "Bon d'achat",
  "addNewPaymentMethod": "Ajouter un nouveau moyen de paiement",
  "useKnownPaymentMethod": "Utiliser un moyen de paiement connu",
  "SEPA_DEBIT": "SÉPA DÉBIT",
  "CARD": "CARTE",
  "updateMyInformation": "Modifier mes informations",
  "shippedTo": "Livré à",
  "notSupportedShippingAddress": "Cette adresse de livraison se situe dans un pays non supporté. Veuillez choisir une adresse de livraison valide.",
  "addNewShipping": "Ajouter une adresse",
  "deliveryMethod": "Mode de livraison",
  "paymentInformation": "Information de paiement",
  "otherPaymentMethods": "Autres méthodes de paiement",
  "availablePaymentMethods": "Méthodes de paiement disponibles",
  "payment": "Paiement",
  "paymentBy": "Paiement par {{paymentMethod}}",
  "creditcard": "Carte de crédit",
  "ideal": "Ideal",
  "idealBank": "Banque Ideal",
  "bancontact": "Bancontact",
  "applePay": "Apple Pay",
  "googlePay": "Google Pay",
  "yourBrowserDoesNotSupportThisPayment": "Votre navigateur ne prend pas en charge ce paiement",
  "processing": "Traitement ...",
  "sofort": "Sofort",
  "deliveryMethodRequired": "Vous devez sélectionner un mode de livraison pour accéder au formulaire de paiement",
  "home": "Domicile",
  "shippingAddressName": "Nom de votre adresse",
  "giveNameToYourAddress": "Donnez un nom à votre adresse pour la retrouver facilement",
  "shippingAddress": "Adresse de livraison",
  "order": "Commande",
  "yourOrder": "Votre commande",
  "workingDays": "{{days}} jours ouvrés",
  "bpost": "Bpost",
  "withoutDelivery": "Sans livraison",
  "collectTheOrderAtTheWarehouse": "Récupérer la commande à l'entrepôt",
  "cardNumber": "Numéro de carte",
  "expirationDate": "Date d'expiration",
  "cvc": "CVC",
  "payButton": "Payer {{amount}}",
  "pay": "Payer",
  "validateOrder": "Valider la commande",
  "shippingPrice": "Frais de port",
  "shipping": "Livraison",
  "pickupPoint": "Points relais",
  "freeShippingInfo": "Encore {{amount}} pour la livraison gratuite",
  "freeDeliveryBenefit": "Vous bénéficiez de la livraison offerte",
  "free": "Gratuit",
  "selectedShippingAddress": "Séléctionner une adresse de livraison",
  "yourCartIsEmpty": "Votre panier est vide",
  "goToTheShop": "Accéder à la boutique",
  "shippingAddressRequired": "Vous devez ajouter une adresse de livraison et sélectionner le mode de livraison pour accéder au formulaire de paiement",
  "search": "Recherche",
  "shippingInformation": "Informations de livraison",
  "waitingDeliveryStatus": "Commande reçue",
  "processingDeliveryStatus": "Préparée en attente de récupération",
  "deliveredDeliveryStatus": "Livré",
  "inTransitDeliveryStatus": "En transit",
  "inPickupPointDeliveryStatus": "En attente au point relais",
  "backToSenderDeliveryStatus": "Retour à l'expéditeur",
  "selectShippingMethod": "Veuillez sélectionner le mode de livraison",
  "congratulationNewOrder": "Félicitations, votre commande a été validée",
  "youWillBeRedirectedInFewSeconds": "Vous allez être redirigé automatiquement dans quelques secondes.",
  "paymentInProgress": "Votre paiement est en cours de traitement",
  "paymentProcessing": "Votre paiement est en cours de traitement, vous allez recevoir un email dès la validation de votre commande.",
  "yourPaymentIsBeingValidated": "Votre paiement est en cours de validation",
  "paymentOperationMayTakeAWhile": "Cette opération peut durer un moment. Vous pouvez fermer votre navigateur. Un email vous sera envoyé dès que votre paiement sera finalisé.",
  "paymentMethodNeedAction": "Votre méthode de paiement nécessite une action supplémentaire pour être finalisée.",
  "paymentMethodNeedActionDescription": "Votre banque a besoin d'une action supplémentaire pour traiter le paiement comme le 3D secure. Après validation cliquez sur le bouton ci-dessous.",
  "verifyAgain": "Vérifiez à nouveau",
  "chooseAnotherPaymentMethod": "Choisissez un autre mode de paiement",
  "unableToVerifyYourPayment": "Nous ne parvenons pas a vérifier votre paiement. Si l'opération persiste plus d'une minute. Veuillez nous contacter pour nous faire part de votre erreur.",
  "accountHolderName": "Nom du titulaire du compte",
  "paymentError": "Le paiement n'a pas été effectué, veuillez rééssayer ou choisir une autre méthode de paiement.",
  "paymentCanceled": "Le paiement n'a pas été effectué, veuillez rééssayer ou choisir une autre méthode de paiement.",
  "monday": "Lundi",
  "tuesday": "Mardi",
  "wednesday": "Mercredi",
  "thursday": "Jeudi",
  "friday": "Vendredi",
  "saturday": "Samedi",
  "sunday": "Dimanche",
  "select": "Sélectionner",
  "closingFromTo": "Fermé du {{closedFrom}} au {{closedTo}}",
  "choosePickupPoint": "Choisissez votre point relais",
  "choosePacketDelivery": "Choisissez votre casier",
  "viewInList": "Voir en liste",
  "viewOnMap": "Voir sur la carte",

  //PROFILING
  "consultantMet": "Je connais un consultant Nutri-logics",
  "firstName": "Prénom",
  "lastName": "Nom",
  "profilingDescription": "Votre candidature sera examinée par le responsable du recrutement qui vous contactera dans les plus brefs délais.",
  "profilingApplicationAlreadyAccepted": "Votre candidature a déjà été acceptée.",
  "goToProSection": "Aller vers l'espace pro.",
  "temporaryLunchMessage": "Le démarrage officiel de la carrière Nutri-logics est prévu pour fin décembre.",
  "nutritionConsultantMet": "J'ai rencontré un conseiller en nutrition Nutri-logics",
  "nutritionConsultantCode": "Identifiant du conseiller en nutrition Nutri-logics",
  "birthday": "Date de naissance",
  "locality": "Localité",
  "levelOfStudy": "Niveau d'études",
  "additionalCertification": "Certification supplémentaire",
  "currentOccupation": "Profession actuelle",
  "currentStatus": "Statut actuel",
  "unemployed": "Sans emploi",
  "employee": "Salarié",
  "pro": "Thérapeute",
  "contractor": "Chef d'entreprise",
  "familySituation": "Situation familiale",
  "inARelationshipWith": "En couple",
  "alone": "Seul(e)",
  "couplesWithChildren": "En couple avec enfant",
  "aloneWithChild": "Seul(e) avec enfant",
  "children": "Combien d'enfant(s) avez vous ?",
  "child": "Enfant",
  "child1": "Âge enfant 1",
  "child2": "Âge enfant 2",
  "child3": "Âge enfant 3",
  "child4": "Âge enfant 4",
  "child5": "Âge enfant 5",
  "child6": "Âge enfant 6",
  "yearOld": "an",
  "yearOld_plural": "ans",
  "moreThan20Year": "plus de 20 ans",
  "ageOfChildren": "Âge du ou des enfant(s)",
  "driverLicense": "Permis de conduire",
  "vehicle": "Véhicule",
  "certifyingTraining": "J'ai déjà suvi une formation certifiante dans les domaines suivants",
  "noTraining": "Aucune formation suivie",
  "commercialField": "Le commercial",
  "generalWellBeing": "Le bien-être en général",
  "nutrition": "La nutrition",
  "coaching": "Le coaching",
  "management": "Le management",
  "groupStrategy": "La stratégie de groupe ou d'entreprise",
  "medicalDelegation": "La délégation médicale ou pharmaceutique",
  "nameOfInstitution": "Nom du/des l'établissement(s) certifiant(s)",
  "howMuchTime": "Combien de temps par semaine puis-je consacrer à mon activité Nutri-logics ?",
  "lessThan5Hours": "moins de 5 heures",
  "between8and10": "entre 8h et 10h",
  "moreThan10": "plus de 10h",
  "timeToTraining": "Puis-je consacrer du temps à la formation ?",
  "abilityAndPatienceToBeTeachable": "J'ai la capacité et la patience d'être enseignable ?",
  "speakFollowingLanguages": "Je parle correctement les langues suivantes",
  "dutch": "Neerlandais",
  "german": "Allemand",
  "other": "Autre",
  "motivationsOrientedOn": "Mes motivations sont orientées sur",
  "possibilityOfDevelopingTeam": "La possibilité de développer une équipe",
  "developmentStaff": "Le développement personnel",
  "wellBeing": "Le bien-être",
  "money": "L'argent",
  "nutritionConsultantCertification": "La certification de conseiller en nutrition",
  "openingNutri-logicsCenter": "La possibilité d'ouvrir un centre Nutri-logics",
  "statusOfIndependent": "Le statut d'entrepreneur indépendant",
  "doesNotMatchMe": "Ne me correspond pas",
  "noReviews": "Aucun avis",
  "whatResourcesCanIBring": "Quelles ressources puis-je apporter à Nutri-logics ?",
  "passions": "Mes passions",
  "yourLifeSentenceSayingSlogan": "Votre phrase, dicton ou slogan de vie",
  "priorityChoiceForChildren": "Quels est votre choix prioritaire pour vos enfants ?",
  "cleanerLand": "Leur laisser une terre plus propre",
  "someCapital": "Leur laisser du capital",
  "conceptOfRespect": "La notion de respect",
  "valueOfMoney": "La valeur de l'argent",
  "devotingBudgetOf1300": "Consacrer un budget de 1300€ pour une formation en nutrition, c'est :",
  "excessive": "Excessif",
  "reasonable": "Raisonnable",
  "opportunity": "Une opportunité",
  "useless": "Inutile",
  "difficult": "Difficile",
  "impossible": "Impossible",
  "describePersonality": "Décrivez en quelques phrases votre personnalité",
  "whatTemper": "Quel est mon tempérament ?",
  "othersWouldSay": "Les autres diraient de moi, que je suis quelqu'un de",
  "magicWand": "Si vous aviez une baguette magique pour changer ou apporter quelque chose a ce monde, ce serait ?",
  "motivations": "Mes motivations sont orientées sur",
  "consultantId": "ID du consultant",
  "interestedMe": "M'intéresse",
  "preferateStatus": "Mon statut préféré",
  "independent": "Indépendant",
  "official": "Fonctionnaire",
  "annuitant": "Rentier",
  "childrenTransmission": "Ce que je souhaite transmettre à mes enfants (Une seule réponse possible)",
  "leaveThemACleanerLand": "Leur laisser une terre plus propre",
  "notionOfCapitalism": "La notion du capitalisme (avantages et inconvénients)",
  "notionOfRespect": "La notion du respect",
  "selfAwareness": "La connaissance de soi",
  "selfEsteem": "L'estime de soi",
  "selfAffirmation": "L'affirmation de soi",
  "senseOfEffortAndEesilience": "Le sens de l'effort et la résilience",
  "indulgenceTowardsOneselfAndOthers": "L'indulgence envers soi-même et les autres",
  "seeLifeOnTheBrightSide": "Voir la vie du bon côté et avoir du plaisir",
  "calm": "Calme",
  "dynamic": "Dynamique",
  "extrovert": "Extraverti",
  "introverted": "Introverti",
  "others": "Autres",
  "changeTheWorld": "Si je devais changer ou apporter quelque chose à ce monde, ce serait",
  "negativeMindAboutActivity": "Qu'est ce qui pourrait m'effrayer ou me déplaire dans ce genre d'activité",
  "switchIfNoConsultant": "Switcher ce bouton si vous ne connaissez pas de consultant",
  "no": "Non",
  "yes": "Oui",
  "profilingSubmit": "Envoyer le profilage",
  "profilingTestSent": "Le test de profilage a bien été envoyé",
  "searchConsultant": "Rechercher un consultant",
  "searchNutritionConsultant": "Rechercher votre conseiller en nutrition",
  "searchByNameOrCity": "Rechercher par nom, ville ...",
  "searchByNameCityOrPostalCode": "Nom, ville ou code postal",
  "searchByIdentifierCityOrPostalCode": "Identifiant, ville ou code postal",
  "SearchYourConsultantByIdentifierCityOrPostalCode": "Recherchez votre consultant par identifiant, ville ou code postal",
  "noNutritionConsultantFound": "Aucun consultant trouvé",
  "findNutritionConsultantNearYou": "Trouver un conseiller près de chez vous",

  //THE MAG
  "theMAG": "Le mag",
  "magMetaDescription": "",
  "linksAboveAreProLinks": "Partage PRO: Connexion automatique à votre boutique.",
  "linksAreNotEnabled": "Les liens PRO ne sont pas activés",
  "shareLink": "Lien de partage",
  "customizeYourLink": "Vous pouvez personnaliser votre lien en cliquant ici",
  "proLinksDisabled": "Vous n'avez pas activé votre lien pro de partage. Vous pouvez le parametrer en cliquant sur le bouton ci-dessous. Le lien pro vous permet de connecter immédiatement à votre boutique les personnes qui le suivront.",
  "configureYourProLink": "Configurez votre lien pro",
  "beContactedButtonDescription": "Si vous désirez être contacté par un de nos experts, veuillez cliquer ici :",
  "beContactedByExpertButton": "Être contacté par l'un de nos experts",
  "beContactedButton": "Être contacté",

  //AUTHENTICATION
  "welcome": "Bienvenue {{name}}",
  "welcomeToNutri-logics": "Bienvenue chez Nutri-logics",
  "createPassword": "Créer votre mot de passe",
  "cookieConsentMessage": "Nous utilisons les cookies afin de fournir les services et fonctionnalités proposés sur notre site et afin d’améliorer l’expérience de nos utilisateurs",
  "iUnderstand": "Je comprends",
  "signInMetaDescription": "SignInMetaDescription",
  "signOutAllDevices": "Se déconnecter de tous les appareils",
  "signOut": "Se déconnecter",
  "proCodeTooltip": "Le code pro correspond à votre thérapeute ou médecin, si vous ne connaissez pas votre code, veuillez inscrire NUTRILOGICS"  ,
  "forgotPasswordMetaDescription": "forgotPasswordMetaDescription", //TODO
  "youAreNoLongerConnected": "Vous n'êtes plus connecté",
  "signIn": "Se connecter",
  "signUp": "Inscription",
  "submitForgotPassword": "Envoyer l'email de récupération",
  "rememberMe": "Rester connecté",
  "phone": "Téléphone",
  "password": "Mot de passe",
  "confirmPassword": "Confirmer le mot de passe",
  "forgotPassword": "Mot de passe oublié",
  "email": "Email",
  "streetNumber": "Numéro de voie",
  "streetName": "Nom de la voie",
  "additionalInfo": "Informations complémentaires (facultatif)",
  "zip": "Code postal",
  "city": "Ville",
  "country": "Pays",
  "countryFieldInfo": "2 lettres, ex: FR",
  "useCustomFields": "L'adresse est introuvable ? Utilisez le formulaire classique",
  "useAutocompleteField": "Utiliser le champ de saisie automatique",
  "role": "Role",
  "askForEmailPassword": "Demander un email de changement de mot de passe",
  "shippingCountry": "Pays de livraison",
  "newInTheWebsite": "Nouveau sur le site",
  "shopEnterButton": "Entrer dans la boutique",
  "iKnowAConsultant": "Je connais un consultant Nutri-logics",
  "iSearchAConsultant": "Je ne connais pas de consultant Nutri-logics et j'en recherche un sur la carte",
  "iDontWantToHaveAConsultant": "Je connais les compléments alimentaires et je continue sans consultant",
  "findANutritionConsultant": "Trouver un conseiller en nutrition",
  "findYourNutritionConsultant": "Retrouvez votre conseiller en nutrition",
  "findYourNutritionConsultantOrNutri-logicsPartner": "Retrouvez votre conseiller en nutrition ou partnaire Nutri-logics",
  "iHaveACode": "J'ai un identifiant",
  "iDoNotHaveCode": "Je n'ai pas d'identifiant",
  "enterNutritionConsultantCode": "Entrez l'identifiant de votre conseiller",
  "codeNotLinkedError": "Cet identifiant n'existe pas",
  "youKnowNutri-logicsConsultant": "Connaissez-vous un conseiller en nutrition ?",
  "youDontKnowNutri-logicsConsultant": "Vous ne connaissez pas de conseiller ?",
  "findConsultantNearYou": "Trouver un conseiller près de chez vous",
  "searchOnTheMap": "Rechercher sur la carte",
  "continueToTheStore": "Continuer vers la boutique",
  "continueToTheStoreWithoutConsultant": "Continuer vers la boutique sans conseiller",
  "comebackToPreviousChoices": "Revenir aux choix précédents",
  "alreadyClient": "Déjà client",
  "back": "Retour",
  "backToTheSiteButton": "Retour au site",
  "updatePassword": "Modifier son mot de passe",
  "passwordUpdated": "Le mot de passe a été mis à jour",
  "errorMessagePasswordUpdateLinkDisabled": "Le lien de changement de mot de passe a été désactivé",
  "updatePasswordEmailSent": "Un email de changement de mot de passe vous a été envoyé à l'adresse {{email}}",
  "sameAsBillingAddress": "Même adresse de livraison que celle de facturation",
  "createMyAccount": "Créer mon compte",
  "requiredFieldInAddressSnackbar" : "Vous devez renseigner le champ « {{field}} »",
  "streetNumberField" : "numéro de rue",
  "streetNameField" : "rue",
  "zipField" : "code postal",
  "cityField" : "ville",
  "countryField" : "pays",
  "registrationInformation": "Enregistrement",
  "howDidYouKnowNutri-logics": "Comment avez-vous connu Nutri-logics ?",
  "doctor": "Médecin",
  "therapist": "Thérapeute",
  "socialNetworks": "Réseaux sociaux",
  "googleSearch": "Recherche Google",
  "nutritionConsultantNameRegistration": "Nom du conseiller en nutrition",
  "doctorNameRegistration": "Nom du médecin",
  "socialNetworkNameRegistration": "Nom du réseau social",
  "otherNameRegistration": "Veuillez préciser",
  "youAreAlreadyConnectedWithSignOutConfirmation": "Vous êtes déjà connecté avec le compte {{name}}, poursuivre la connexion avec le nouveau compte ?",
  "helpBoxTitle": "Besoin d'aide ?",
  "helpBoxText": "N'hésitez pas à nous contacter pour une assistance :",

  //ACCOUNT
  "accountMetaDescription": "Votre compte Nutri-logics",
  "myAccount": "Mon compte",
  "birthDate": "Date de naissance",
  "account": "Compte",
  "addresses": "Adresses",
  "myShippingAddresses": "Mes adresses de livraison",
  "orders": "Commandes",
  "myOrders": "Mes commandes",
  "healthCheck": "Bilan santé",
  "healthCheckMin": "Bilan",
  "settings": "Paramètres",
  "mailingAndDataLanguage": "Langage de communication (mailing, etc...)",
  "french": "Français",
  "english": "Anglais",
  "billingAddress": "Adresse de facturation",
  "company": "Entreprise (facultatif)",
  "taxNumber": "Numéro de TVA (facultatif)",
  "vat": "TVA",
  "updateMyAccount": "Mettre à jour mon compte",
  "newAddress": "Nouvelle adresse",
  "addNewShippingAddress": "Ajouter une nouvelle adresse",
  "removeShippingAddress": "Supprimer l'adresse",
  "contactAdminToChangeBillingAddress": "Vous ne pouvez pas modifier votre adresse de facturation, veuillez contacter un administrateur",
  "address": "Adresse",
  "defaultShippingAddress": "Adresse de livraison par défaut",
  "defaultBillingAddress": "Adresse de facturation par défaut",
  "selectDefaultShippingAddressButton": "Utiliser comme adresse de livraison par défaut",
  "selectDefaultBillingAddressButton": "Utiliser comme adresse de facturation par défaut",
  "addShippingAddressButton": "Ajouter ma nouvelle adresse",
  "updateShippingAddressButton": "Modifier l'adresse",
  "addressSelectedAsDefault": "Adresse séléctionnée par default",
  "addressRemoved": "Adresse supprimée",
  "addressAdded": "Adresse ajouté",
  "addressUpdated": "Adresse modifiée",
  "cancelButton": "Annuler",
  "orderRefunded": "Commande remboursée",
  "orderCanceled": "Commande annulée",
  "refunded": "Remboursé(s)",
  "delayedSending": "Envoi différé",
  "gotBackToSender": "Commande retournée à l'expéditeur",
  "orderAwaitingPreparation": "Commande en attente de préparation",
  "orderPreparedAwaitingDelivery": "Commande préparée en attente de livraison",
  "orderInTransit": "Commande en cours de livraison",
  "orderInPickupPoint": "Commande au point relais",
  "orderBackToSender": "Commande renvoyée à l'expéditeur",
  "orderDelivered": "Commande livrée",
  "orderActions": "Actions de la commande",
  "orderCompleted": "Commande terminée",
  "recommendationOrder": "Recommandation de votre conseiller en nutrition",
  "orderHistory": "Historique de la commande",
  "recommendationOrderFromNutritionConsultant": "Recommendation de votre conseiller en nutrition",
  "freeOrderFromNutri-logics": "Commande offerte par Nutri-logics",
  "deferredOrderDueToOutOfStock": "Commande différée en raison d'une rupture de stock sur la commande précédente",
  "orderCreated": "Commande créée",
  "invoiceCreated": "Facture créée",
  "thereIsNotShippingForThisOrder": "Il n'y a pas de livraison pour cette commande",
  "orderStatus": "Statut de la commande",
  "orderAgain": "Commander à nouveau",
  "orderPrescription": "Commander les produits de la prescription",
  "prescriptionSentStatus": "Prescrition en attente",
  "prescriptionOrderedStatus": "Prescription commandée",
  "prescriptionExpiredStatus": "Prescription expirée",
  "prescriptionInitializedStatus": "Ajoutée au panier",
  "orderTracking": "Suivi de la commande",
  "deferredShipping": "Livraison différée",
  "downloadInvoice": "Télécharger la facture",
  "mailingCampaignFromNL": "Campagnes de mails proposé par Nutri-logics",
  "mailingCampaignFromOurAgreedSeller": "Campagnes de mails proposé par votre contact agréé",
  "emailNotifications": "Notifications par emails",
  "youCanChangeThisAtEveryMoment": "Vous pouvez changer votre choix à n'importe quel moment depuis cette page",
  "security": "Sécurité",
  "orderThisRecommendation": "Passer commande",
  "shippingAddresses": "Adresses de livraison",
  "updateShippingAddress": "Modifier l'adresse de livraison",
  "removeAddress": "Supprimer l'adresse",
  "updateInformation": "Modifier les informations",
  "emailAddedToMailingList": "Votre e-mail a été ajouté à notre liste de diffusion",
  "emailRemovedFromMailingList": "Votre e-mail a été supprimé de notre liste de diffusion",
  "emailUpdateRequested": "Pour mettre à jour votre email, veuillez cliquer sur le lien de validation envoyé à l'adresse: {{email}}",
  "emailUpdated": "Email correctement ajouté à votre compte",
  "invalidUpdateEmailLinkDescription": "Le lien que vous avez suivi est actuellement invalide ou inactif. Pour valider cet e-mail, vous devez renouveller votre demande en mettant à jour votre compte.",
  "cannotAccessToYourAccount": "Si vous ne pouvez pas accéder à votre compte et à votre service de messagerie actuel, veuillez nous contacter.",

  //ANAMNESIS
  "anamnesis": "Anamnèse",
  "needPreviousPage": "Vous devez remplir la page précédente pour continuer l'anamnèse.",
  "anamnesisInfo": "Vous pouvez remplir cette anamnèse en plusieurs fois. <1/> Chaque page validée est sauvegardée.",
  "anamnesisCompleted": "Félicitations, l'anamnèse est terminée!",
  "sendTheAnamnesisDescription": "Pour envoyer votre anamnèse, il vous suffit de cliquer sur le bouton ci-dessous.<1/>Votre conseiller vous contactera très prochainement.",
  "sendTheAnamnesisAcceptationDescription": "J'accepte que les informations saisies sont exploitées à des fins d'accompagnement et de conseil pour ma santé.",
  "sendAdditionalDocuments": "Envoyer des documents",
  "uploadAnamnesisRelativesDocuments": "Optionnel: Vous pouvez si besoin ajouter des documents supplémentaires (test sanguin, etc ...)",
  "dropzoneText": "Faites glisser les documents dans le cadre ou cliquez ici",
  "preview": "Aperçu",
  "sendDocuments": "Envoyer les documents",
  "documentsSent": "Documents envoyés",
  "deleteDocument": "Supprimer le document: {{name}}",
  "documentsUploaded": "Documents envoyés",
  "createdAt": "Créé le {{date}}",
  "updatedAt": "Modifié le {{date}}",
  "sendTheAnamnesis": "Envoyer l'anamnèse",
  "watchTheAnamnesis": "Voir l'anamnèse",
  "anamnesisSent": "L'anamnèse a été envoyée",
  "anamnesisSentDescription": "L'anamnèse a été envoyée le {{date}}",
  "anamnesisContactDescription": "Si vous avez des questions, n'hésitez pas à contacter votre conseiller en nutrition en suivant ce lien :",
  "gender": "Sexe",
  "male": "Homme",
  "female": "Femme",
  "civilStatus": "Etat civil",
  "whatDoYouWantByCompletingThisAnamnesis": "Que désirez-vous en complétant cette anamnèse?",
  "haveBreakfastWeek": "Manger vous le petit déjeuner la semaine (hors weekend)?",
  "haveBreakfastWeekend": "Manger vous le petit déjeuner le weekend?",
  "whatBreakfastWeek": "Que mangez vous au petit déjeuner la semaine (hors weekend)?",
  "whatBreakfastWeekend": "Que mangez vous au petit déjeuner le weekend?",
  "haveLunchWeek": "Mangez le déjeuner la semaine (hors weekend)?",
  "haveLunchWeekend": "Mangez le déjeuner le weekend?",
  "whatLunchWeek": "Que mangez vous au déjeuner la semaine (hors weekend)?",
  "whatLunchWeekend": "Que mangez vous au déjeuner le weekend?",
  "haveDinnerWeek": "Mangez vous le diner la semaine (hors weekend)?",
  "haveDinnerWeekend": "Mangez vous le diner le weekend?",
  "whatDinnerWeek": "Que mangez vous au diner la semaine (hors weekend)?",
  "whatDinnerWeekend": "Que mangez vous au dîner le weekend?",
  "otherTimeYouEat": "Existe-t-il d'autres moments dans la journée où vous mangez?",
  "food": "Aliment",
  "quantity": "Quantité",
  "whatTime": "A quelle heure?",
  "whatPosition": "Debout ou assis?",
  "standing": "Debout",
  "seated": "Assis",
  "frontOfTV": "Devant la TV?",
  "sameEveryday": "Est-il le même tous les jours?",
  "otherFood": "Quels autres types d'aliments prenez-vous?",
  "frequency": "Fréquence",
  "time": "Heure",
  "onceAWeek": "Une fois par semaine",
  "severalTimesAWeek": "Plusieurs fois par semaine",
  "everyDays": "tous les jours",
  "haveASnack": "Faites-vous un goûter?",
  "snackWhatTime": "A quelle heure?",
  "validateAndContinue": "Valider et continuer",
  "validate": "Valider",
  "previousStep": "Retour",
  "addRow": "Ajouter une ligne",
  "whatAreYouDrinking": "Que buvez-vous? Quelles quantités et fréquences?",
  "howMany": "Combien?",

  "water": "Eau",
  "wine": "Du vin",
  "beer": "Bière",
  "cider": "Cidre",
  "aperitifs": "Apéritifs",
  "juice": "Jus",
  "lightDrink": "Boisson light",
  "sweetDrink": "Boisson sucrée (jus de fruits, sirop, soda, etc.)",
  "infusionOrTea": "Infusion, thé",
  "coffee": "café",
  "sugarPerCup": "Combien de morceaux de sucre utilisez-vous par tasse?",
  "aspartameOrSweetenerPerCup": "Combien de morceaux de sucre (aspartame, édulcorant) utilisez-vous par tasse?",
  "milk": "Lait",
  "milkType": "Type de lait",
  "skimmed": "Écrémé",
  "semiSkimmed": "Demi-écrémé",
  "wholeMilk": "Lait entier",
  "vegetable": "Légume",

  "iDontDrinkIt": "Je n'en bois pas",
  "lessThanHalfALiterPerDay": "Moins d'un demi-litre par jour",
  "betweenHalfALiterAndALiterPerDay": "Entre un demi-litre et un litre par jour",
  "moreThanALiterPerDay": "Plus d'un litre par jour",
  "1GlassPerDay": "Un verre par jour",
  "lessThan1PerDay": "Moins d'une par jour",
  "lessThan1GlassPerDay": "Moins d'un verre par jour",
  "1To3GlassesPerDay": "1 à 3 verres par jour",
  "2To3GlassesPerDay": "2 à 3 verres par jour",
  "4To7GlassesPerDay": "4 à 7 verres par jour",
  "moreThan1GlassPerDay": "Plus d'un verre par jour",
  "moreThan3GlassesPerDay": "Plus de 3 verres par jour",
  "moreThan7GlassesPerDay": "Plus de 7 verres par jour",
  "1GlassPerWeek": "1 verre par semaine",
  "1PerDay": "Une par jour",
  "moreThan1PerDay": "Plus d'1 par jour",
  "lessThan1CupPerDay": "Moins d'une tasse par jour",
  "1CupPerDay": "Une tasse par jour",
  "moreThan1CupPerDay": "Plus d'une tasse par jour",
  "2To3CupsPerDay": "2 à 3 tasses par jour",
  "moreThan3CupsPerDay": "Plus de 3 tasses",
  "noSugar": "Pas de sucre",
  "noAspartameOrSweetener": "Sans aspartame ni édulcorant",
  "1PerCup": "1 par tasse",
  "2PerCup": "2 par tasse",
  "moreThan2PerCup": "Plus de 2 par tasse",

  "whatAreYouEating": "Que mangez-vous? Quelles quantités et fréquences",
  "rawVegetables": "Crudités (légumes crus)",
  "cookedVegetables": "Légumes cuits",
  "driedVegetables": "Légumes secs (lentilles, haricots, blancs, pois chiches, etc.)",
  "rawFruits": "Fruits crus",
  "cookedFruit": "Fruits cuits",
  "meat": "Viande",
  "reconstitutedProducts": "Produits reconstitués tel que nuggets, cordon bleu, ...",
  "coldCuts": "Charcuterie (jambon, rillettes, pâtés, etc.)",
  "fish": "Poisson",
  "eggs": "Oeufs",
  "starterPuffPastry": "Entrée à base de feuilleté ou tartes?",
  "cheeses": "Fromages",
  "starchyFoods": "Féculents (riz, pâtes, pommes de terre)",
  "friedFoods": "Fritures (frites, chips, beignet, etc.)",
  "portionOfMilk": "Combien de fois dans la journée, en  comptant le petit déjeuner, prenez-vous une part de laitage (yaourt, fromage blanc) ou verre de lait?",
  "sweetDesserts": "Consommez-vous des desserts sucrés (pâtisseries, glaces, entremets)?",
  "pastries": "Consommez-vous des viennoiseries, croissant?",
  "sweetProducts": "Combien consommez-vous des produits sucrés (bonbons, chocolat, sucrerie etc.)?",
  "bread": "Combien consommez-vous de pain/jour ? (une baguette = 200g)?",
  "breadSlices": "Combien consommez-vous de tranche de pain/jour ? (une tranche = +/-35g)?",
  "breadType": "Type de pain",
  "white": "Blanc",
  "grey": "Gris",
  "wholeWheatBread": "Complet",
  "spelled": "Epeautre",
  "otherBreadType": "Autre type de pain",
  "lowFatProducts": "Utilisez-vous des produits allégés?",
  "everyMeal": "À chaque repas",
  "1TimePerDay": "Une fois par jour",
  "1TimePerWeek": "Une fois par semaine",
  "1To3TimePerWeek": "1 à 3 fois par semaine",
  "3To6TimePerWeek": "3 à 6 fois par semaine",
  "1TimePerMonth": "1 fois par mois",
  "2To4TimesPerWeek": "2 à 4 fois par semaine",
  "moreThan1TimePerWeek": "Plus d'une fois par semaine",
  "reducedInSugarOrFat": "Allégés en sucre ou en graisse?",
  "reducedInSugar": "Allégés en sucre",
  "reducedInFat": "Allégés en graisse",
  "moreThan1": "Plus de 1",

  "1To2TimesPerMonth": "1 à 2 fois par mois",
  "never": "Jamais",
  "always": "Tout le temps",
  "lessThan1TimePerWeek": "Moins d'une fois par semaine",
  "everyday": "Tous les jours",
  "4TimesPerWeek": "4 fois par semaine",
  "moreThan4TimesPerWeek": "Plus de 4 fois par semaine",
  "2PortionsPerMeal": "2 portions par repas",
  "1PortionPerMeal": "1 portion par repas",
  "1PortionPerDay": "1 portion par jour",
  "lessThan1PortionPerDay": "Moins d'une portion par jour",
  "3TimePerDay": "3 fois par jour",
  "moreThan3TimePerDay": "Plus de 3 fois par jour",
  "2TimesPerWeek": "2 fois par semaine",
  "moreThan2TimesPerWeek": "Plus de 2 fois par semaine",
  "lessThan100g": "Moins de 100g",
  "100gTo200g": "100g à 200g",
  "moreThan200g": "Plus de 200g",

  "cookingOrSeasoningMode": "Mode cuissons ou d'assaisonnement le plus régulièrement utilisé",
  "cookingMeat": "Cuisson de la viande",
  "blue": "Bleu",
  "rareMeat": "Saignante",
  "wellCooked": "Bien cuite",
  "SeasoningMeat": "Assaisonnement de la viande",
  "seasoningVegetables": "Assaisonnement des légumes",
  "seasoningSalads": "Assaisonnement de la salade",
  "butter": "Beurre",
  "lessThanAPhalanx": "Moins d'une phalange",
  "equivalentOfAPhalanx": "Équivalent d'une phalange",
  "moreThanAPhalanx": "Plus d'une phalange",
  "doYouEatButterEveryDay": "Consommez vous du beurre tous les jour?",
  "cream": "Crème",
  "margarine": "Margarine",
  "oils": "Huiles",
  "aTablespoon": "Une cuillère a soupe",
  "lessThanATablespoon": "Moins d’une cuillère a soupe",
  "moreThanATablespoon": "Plus d’une cuillère a soupe",
  "doYouEatOilEveryDay": "Consommez vous de l'huile tous les jour?",
  "1TablespoonOfOilPerPerson": "Une cuillère à soupe d'huile par personne",
  "moreThan1TablespoonOfOilPerPerson": "Plus d'une cuillère à soupe d'huile par personne",
  "amountOfButterConsumedPerDay": "Quantité consommée de beurre par jour",
  "amountOfButterConsumedPerWeek": "Quantité consommée de beurre par semaine",
  "amountOfButterConsumedPerMonth": "Quantité consommée de beurre par mois",
  "amountOfOilConsumedPerDay": "Quantité consommée d'huile par jour",
  "amountOfOilConsumedPerWeek": "Quantité consommée d'huile par semaine",
  "amountOfOilConsumedPerMonth": "Quantité consommée d'huile par mois",
  "foodsThatYouShouldNotEat": "Existe-t-il des aliments que vous ne supportez pas, ou que vous ne devez (pouvez) pas manger?",
  "intolerantOrAllergicFood": "Êtes-vous intolérant ou allergique à des aliments?",
  "which": "Lesquels",

  "lifestyle": "Les habitudes de vie",
  "areYouWorking": "Travaillez-vous actuellement?",
  "notWorkingStatus": "Statut",
  "retired": "Retraité",
  "invalid": "Invalide",
  "noProfession": "Sans profession",
  "schedule": "Horaire",
  "officeSchedule": "Horaire Bureau",
  "alternateDayNightSchedule": "Horaire alterné jour / nuit",
  "whatProfession": "Quelle est votre profession?",
  "youLive": "Vous vivez",
  "withFamily": "En famille",
  "inCohabitation": "En concubinage",
  "withRoommates": "En collocation",
  "howManyPeople": "Combien de personnes?",
  "howManyAdults": "Combien d'adultes?",
  "howManyChildren": "Combien d'enfants?",

  "religionInfluenceYourDiet": "Votre culture, religion influence-t-elle votre alimentation?",
  "whoMakesFoodPurchases": "Qui réalise les achats alimentaires? (Ou?, Quand?,Avec qui?)",
  "isIt": "Si c'est vous, est-ce?",
  "aPleasure": "Un plaisir",
  "aConstraint": "Une contrainte",
  "whoPreparesTheMealsAtHome": "Chez vous, qui prépare les repas?",
  "doYouLikeToCook": "Aimez-vous cuisiner?",
  "typeOfPreparations": "Quel type de préparations?",
  "doYouTasteTheDishes": "Goûtez-vous les plats?",
  "timeToPrepareMeals": "De quel temps disposez-vous pour préparer les repas?",
  "equipmentForCooking": "De quel équipement disposez-vous pour cuisiner, conserver vos aliments?",
  "fridge": "Frigo",
  "freezer": "Congélateur",
  "oven": "Four",
  "microwaveOven": "Four micro-onde",
  "cooker": "Cuisinère",
  "howLongIsBreakfast": "Combien de temps dure le petit-déjeuner?",
  "howLongIsLunch": "Combien de temps dure le déjeuner?",
  "howLongIsDinner": "Combien de temps dure le dîner?",
  "howAreYouEatingMidday": "Mangez-vous? Seul(e) ou en compagnie le midi",
  "howAreYouEatingEvening": "Mangez-vous? Seul(e) ou en compagnie le soir",
  "inCompany": "En compagnie",
  "doYouEatBy": "Mangez-vous par",
  "necessity": "Nécessité",
  "hunger": "Faim",
  "gluttony": "Gourmandise",
  "howFastDoYouEat": "À quelle vitesse mangez vous",
  "slowly": "Lentement",
  "quickly": "Rapidement",
  "difficultiesInObtainingSupplies": "Avez vous de difficultés pour vous approvisionner, préparer les repas, vous alimenter?",
  "followADiet": "Habituellement suivez-vous un régime alimentaire?",
  "followedOtherDiets": "Avez-vous déjà suivi d'autres régimes?",
  "when": "Quand?",
  "why": "Pourquoi?",
  "prescribedByWhom": "Prescrit par qui?",
  "doYouEverEatAtARestaurant": "Vous arrive-t-il de manger au restaurant?",
  "doYouEverHaveMealsWithFamily": "Vous arrive-t-il de faire des repas de famille, d'amis?",
  "areYouHungry": "Avez-vous faim?",
  "whenStartingTheMeal": "En commencant le repas",
  "byFinishingTheMeal": "En terminant le repas",
  "betweenMeals": "Entre les repas",
  "doYouConsiderYourself": "Estimez-vous être un",
  "bigEater": "Gros mangeur",
  "middleEater": "Mangeur moyen",
  "littleEater": "Petit mangeur",
  "whatFoodsDoYouPrefer": "Quels aliments préférez-vous?",
  "sweety": "Sucrés",
  "salty": "Salés",
  "refillingYourself": "Avez-vous l'habitude de vous resservir à table?",
  "often": "Souvent",
  "sometimes": "De temps en temps	",
  "rarely": "Rarement	",
  "toEveryMeal": "À tous les repas",
  "whatTypesOfDishes": "Plus particulièrement sur quels types de plats?",
  "doYou": "Le faites-vous?",
  "doYouEverSkipABreakfast": "Sautez-vous le petit-déjeuner?",
  "doYouEverSkipALunch": "Sautez-vous le déjeuner?",
  "doYouEverSkipADinner": "Sautez-vous le dîner?",
  "2To3TimesPerWeek": "2 à 3 fois par semaine",
  "moreThan3TimesPerWeek": "Plus de 3 fois par semaine",
  "breakfast": "Le petit déjeuner",
  "atNoon": "A midi",
  "theEvening": "Le soir",
  "DoYouHaveAnySnacks": "Prenez-vous des collations ou en-cas?",
  "inTheMorning": "Dans la matinée",
  "inTheAfternoon": "Dans l'après-midi",
  "afterWork": "Après le travail",
  "inTheEvening": "Dans la soirée",
  "inTheNight": "Dans la nuit",

  "weightHistory": "Historique du poids",
  "weighAt18": "Combien pesiez-vous à 18 ans?",
  "weighAt20": "Combien pesiez-vous à 20 ans?",
  "weighAt25": "Combien pesiez-vous à 25 ans?",
  "weighAt30": "Combien pesiez-vous à 30 ans?",
  "weighAt40": "Combien pesiez-vous à 40 ans?",
  "weighAt50": "Combien pesiez-vous à 50 ans?",
  "weighAt60": "Combien pesiez-vous à 60 ans?",
  "haveYouEverBeenPregnant": "Avez-vous déjà été enceinte?",
  "weighAtPostPregnancy": "Combien pesiez-vous après la grossesse?",
  "areYouMenopausal": "Êtes-vous ménopausée?",
  "weighAtPremenopause": "Combien pesiez-vous à la préménopause?",
  "weighAtMenopause": "Combien pesiez-vous à la ménopause?",

  "physicalActivity": "Activité physique",
  "atYourWorkOrYourMainOccupation": "Sur votre lieu de travail ou pendant votre occupation principale",
  "doYouWalk": "Marchez-vous?",
  "howManyStepsPerDay": "Combien de pas par jour",
  "lessThan7000": "Moins de 7000",
  "moreThan7000": "Plus de 7000",
  "areYouSeated": "Êtes-vous assis?",
  "duringFreeTime": "Pendant votre temps libre",
  "doYouWalkInFreeTime": "Marchez-vous? (Jardinez-vous? Faites-vous activement du bricolage?)",
  "doYouPracticeSport": "Pratiquez-vous un sport?",
  "areYouAdjustingDiet": "Adaptez-vous votre alimentation à votre sport?",
  "doYouWatchTV": "Regardez-vous la télévision? (idem sur PC)",
  "howManyTime": "Combien de fois",
  "howManyTimesPerWeek": "Combien de fois par semaine",
  "howManyHours": "Combien d'heures",
  "getAroundByWalk": "Pour vous déplacer, préférez-vous marcher, faire du vélo, du roller (autres)?",

  "tobacco": "Tabac",
  "doYouSmokeCigarettes": "Fumez-vous des cigarettes?",
  "howManyCigarettesPerDay": "Combien de cigarettes par jour?",
  "sinceHowManyYears": "Depuis combien d'années?",
  "wouldYouLikeToQuitSmoking": "Souhaitez-vous arrêter de fumer?",
  "doYouSmokeElectronicCigarette": "Fumez-vous une cigarette électronique?",
  "howManyElectronicCigarettePerDay": "Combien par jour?",
  "nicotineIntensity": "Intensité de la nicotine (mg / ml)",
  "howManyMLPerDayPerDay": "Combien de millilitres par jour?",
  "wouldYouLikeToQuitSmokingElectronicCigarette": "Souhaitez-vous arrêter de fumer?",

  "duringTheMeal": "Pendant le repas",
  "chewingFrequency": "Votre rythme de mastication",
  "fast": "Rapide",
  "medium": "Moyen",
  "slow": "Lent",
  "wearingDenturesToEat": "Portez-vous des prothèses ou implants dentaires ?",
  "isItWellSupported": "est-il bien pris en charge?",

  "medicalHistory": "Antécédents médicaux",
  "whatIsYourMedicalHistory": "Quels sont vos antécédents médicaux?",
  "whatIsYourSurgicalHistory": "Quelle est votre histoire chirurgicale?",
  "whatIsYourFatherMedicalHistory": "Quels sont les antécédents médicaux de votre père",
  "whatIsYourMotherMedicalHistory": "Quels sont les antécédents médicaux de votre mère",
  "diabetes": "Diabète",
  "cholesterol": "Cholestérol",
  "cancer": "Cancer",
  "areYourTakingDrugTreatment": "Si vous suivez un traitement médicamenteux, veuillez les indiquer",
  "areYouTakingAnyDietarySupplements": "Si vous prenez des compléments alimentaires, veuillez les indiquer",

  "physicalState": "État physique",
  "describeYourPhysicalState": "En général, comment décririez-vous votre état de forme?",
  "whichOfTheseWordsDescribesYouBest": "Lequel de ces mots vous décrit le mieux?",

  "active": "Active",
  "sportsman": "Sportif",
  "stressed": "stressé",
  "depressed": "déprimé",
  "allergies": "Allergies?",
  "conjunctivitis": "Yeux (conjonctivite, orgelets)",
  "mouthHerpes": "Bouche (herpès, impétigo, boutons de fièvre)",
  "mucousMembranesMouthUlcers": "Ulcères buccaux des muqueuses",
  "heartProblems": "Problèmes cardiaques",
  "tension": "Tension (Hypo / hyper)",
  "waterRetention": "Rétention d'eau",
  "location": "Location",
  "liverProblems": "Problèmes de Foie?",
  "kidneyProblems": "Problèmes Rénaux?",
  "pancreaProblems": "Problèmes de Pancréa?",
  "problemsAboutSpleen": "Problèmes de Rate?",
  "difficultyFallingAsleep": "Difficulté à s'endormir?",
  "sleepApnea": "Apnée du sommeil?",
  "nightAwakening": "Réveil nocturne?",
  "stressAnxiety": "Stress / Anxiety?",
  "seasonalDepression": "Dépression, dépression saisonnière?",
  "muscleAchesCrampsTension": "Douleurs, crampes et / ou tensions musculaires?",
  "tendinitis": "Tendinite",
  "artrosis": "Artrosis",
  "thyroidProblem": "Problème de thyroïde?",
  "menstrualCycle": "Cycle menstruel?",
  "regular": "Regular",
  "irregular": "irrégulier",
  "pain": "douleur",
  "majorDiscomfort": "Inconfort majeur",
  "whitishTongue": "Langue blanchâtre?",
  "badBreath": "Mauvaise haleine",
  "esophagealProblem": "Problème œsophagien",
  "stomach": "Estomac (enflé / douleur)",
  "intestines": "Intestins-Colôn (douleur L / R)?",
  "right": "Right",
  "left": "Left",
  "bothSides": "Les deux côtés",
  "flatulence": "Flatulence",
  "noisy": "Bruyant",
  "odorous": "Odorant",
  "noisyOdorous": "Bruyant et odorant",
  "faeces": "Selles",
  "faecesFrequency": "Selles (frequency)",
  "lessThanOncePerDay": "Moins d'une fois / jour",
  "onceADay": "Une fois par jour",
  "moreThanOnceADay": "Plus d'une fois par jour",
  "faecesConsistency": "Consistence des selles",
  "soft": "Molle",
  "longCigar": "Long cigare",
  "rabbitPoop": "Crotte de lapin",
  "inRosary": "En chapelet",
  "smellsOfBotherMe": "Les odeurs de ... me dérangent",
  "smellsOfPerfumes": "Parfums",
  "smellsInShops": "Dans les magasins",
  "smellsOfCooking": "Cuisson",
  "afterAHeartyMealAndADrunkenEvening": "Après un repas copieux et une soirée bien arrosée",
  "feelTiredAfterEvening": "Vous vous sentez fatigué",
  "severalDays": "Plusieurs jours",
  "smearedAfterEvening": "Barbouillé",
  "nauseousAfterEvening": "Nauseous",
  "energyDrops": "Baisses d’energie?",
  "tired": "Coup de pompe?",
  "atWhatTime": "Si oui, à quelle heure?",
  "brittleNails": "Ongles cassants?",
  "hairLoss": "Perte de cheveux?",
  "skinProblems": "Problèmes de peau?",
  "teethingCaries": "Dentition? Caries, appareil ortho, rage de dents",
  "urineIsUsually": "L'urine est généralement",
  "light": "Clair",
  "dark": "Foncé",
  "migraines": "Migraines?",
  "foodAllergy": "Allergie alimentaire?",
  "beingAnInfant": "Etant nourrison",
  "birthByWay": "Naissance par voie",
  "cesarean": "Césarienne",
  "vaginalBirth": "Voie basse",
  "birthWeight": "Poids à la naissance",
  "birthAntibiotic": "Antibiotique",
  "breastfed": "Allaités",
  "babyBottles": "Biberons",
  "howManyMonths": "Combien de mois?",
  "birthGeneralStateOfHealth": "État de santé général",
  "childhood": "Enfance",
  "slenderChild": "Enfant mince",
  "corpulentChild": "Enfant corpulent",
  "childhoodGeneralStateOfHealth": "État de santé général",
  "adolescence": "Adolescence",
  "slenderTeen": "Adolescent mince",
  "corpulentTeen": "Adolescent corpulent",
  "adolescenceGeneralStateOfHealth": "État de santé général",
  "excellent": "Excellent",
  "good": "Bien",
  "weak": "Faible",
  "mediocre": "Médiocre",
  "poor": "Pauvre",

  "centralNervousSystem": "Système nerveux central",
  "involuntaryMovement": "Mouvement involontaire du muscle (bras, abdominaux, quadriceps)",
  "muscleCramps": "Crampes musculaires (mollets, orteils, mains, etc.) aggravées pendant la grossesse",
  "muscleTension": "Tension musculaire, raideur de la nuque, des épaules et du bas du dos",
  "arnoldNeuralgia": "Névralgie d'Arnold, céphalée temporale avec l'impression d'avoir la tête dans un étau",
  "intercostalPain": "Douleur intercostale (comme la pointe de la poitrine, \" pointer vers le cœur \")",
  "HypersensitivityToNoiseAndLight": "Hypersensibilité au bruit et à la lumière",
  "respiratoryTightnessPalpitations": "Oppression respiratoire, palpitations",
  "paresthesiaOfExtremities": "Paresthésie des extrémités (picotements des mains, des pieds), extrémités froides et moites",
  "hyperexcitabilityEmotivityAnxiety": "Hyperexcitabilité - Émotivité - Anxiété",
  "waterRetentionPremenstrualPeriod": "Rétention d'eau, en particulier pendant la période prémenstruelle et pendant la grossesse",
  "irritable": "Irritable",
  "dissatisfiedImpatient": "Insatisfait, Impatient, Sentiment d'être incompris",
  "intoleranceOfConstraintsFrustration": "Intolérance aux contraintes / frustration",
  "cannotStandStress": "Ne supporte pas le stress",
  "moodSwings": "Sautes d’Humeur (individu charmant qui explose pour un oui ou un non)",
  "aggressiveness": "Agressivité",
  "foodCravings": "Fringales, y compris envie de sucreries, grignotage entre les repas",
  "tendencyToAddiction": "Tendance à la dépendance: tabac, alcool, café, etc.",
  "seasonalDepressionLackOfLightAffectsMorale": "La dépression saisonnière (automne, fin d'hiver) / le manque de lumière affecte le moral",
  "alwaysTired": "Toujours fatigué",
  "sleepingTroubles": "Troubles du sommeil",
  "difficultyStartingABusiness": "Difficulté à démarrer une entreprise, difficulté à prendre des décisions",
  "difficultyInContinuingAnAction": "Difficulté à poursuivre une action, marche au ralenti",
  "decreasedInterestInWork": "Diminution de l'intérêt pour le travail, manque de motivation, difficulté à faire des projets",
  "difficultiesInConcentration": "Difficultés de concentration, de mémorisation, d'apprentissage",
  "withdrawal": "Retrait (ne plus vouloir voir d'amis ...), repli sur soi-même, perte de plaisir à faire les choses",
  "feelingOfBeingDepressed": "Sentiment de dépression, douleur morale",
  "feelingOfWorthlessness": "Sentiment d'inutilité, manque de confiance en soi, faible libido",
  "impatienceInTheLegs": "Impatience dans les jambes",
  "absent": "Absent",
  "moderate": "Modéré",
  "embarrassing": "Embarrassant",
  "veryEmbarrassing": "Très embarrassant",

  //SIMPLIFIED ANAMNESIS
  "years": "ans",
  "sentOn": "Envoyé le {{date}}",
  "anamnesisTransmittedOn": "Merci d'avoir complété cette anamnèse. Nous l'avons transmise à votre conseiller en nutrition le {{date}}.",
  "form": "Formulaire",
  "results": "Résultats",
  "chartDetailed": "Graphique détaillé",
  "anamneses": "Anamnèses",
  "noAnamnesis": "Aucune anamnèse",
  "newAnamnesis": "Nouvelle anamnèse",
  "requestGraphAnamnesis": "Demander une anamnèse",
  "restartNewAnamnesis": "Recommencer une nouvelle anamnèse",
  "weight": "Poids",
  "yourWeight": "Votre poids (kg)",
  "height": "Taille",
  "yourHeight": "Votre taille (cm)",
  "bmi": "IMC",
  "underweight": "Maigre",
  "normal": "Normal",
  "overweight": "Surpoids",
  "obese": "Obésité",
  "extremelyObese": "Obésité sévère",
  "youNeedToAcceptMention": "Vous devez accepter la mention pour continuer",
  "uploading": "Envoi en cours",
  "inProgress": "En cours",
  "deleteAnamnesis": "Supprimer l'anamnèse",
  "continue": "Continuer",
  "emptyIfNotConcerned": "Veuillez laisser vide si vous n'êtes pas concerné",
  "sa_anamnesisAcceptationMention": "J’accepte que les informations saisies dans cette anamnèse soient exploitées à des fins d'accompagnement et de conseil pour ma santé",
  "sa_youNeedToAcceptMention": "Vous devez accepter la mention avant de continuer",
  "sa_alimentaryConsommation": "Consommation alimentaire",
  "sa_digestiveSystem": "Systéme digestif",
  "sa_exposureToPollutants": "Exposition aux polluants",
  "sa_sleep": "Sommeil",
  "sa_psychoEmotional": "Psycho-émotionnel",
  "sa_skinDisorders": "Troubles cutanés",
  "sa_osteoarticularDisorders": "Troubles ostéo-articulaires",
  "sa_otherDisorders": "Autres troubles",
  "sa_immunity": "Immunité",
  "sa_cardiovascularDisorders": "Troubles cardiovasculaires",
  "sa_hormonalDisturbances": "Troubles hormonaux",
  "sa_carbohydrateMetabolism": "Métabolisme glucidique",
  "sa_mealDuringTheWeek": "Repas pendant la semaine",
  "sa_mealDuringTheWeekend": "Repas pendant le weekend",
  "sa_yes": "Oui",
  "sa_true": "Oui",
  "sa_no": "Non",
  "sa_false": "Non",
  "sa_x1": "Une fois",
  "sa_x2": "Deux fois",
  "sa_more": "Plus de deux fois",
  "sa_immune": "Immunitaire",
  "sa_essentialFattyAcids": "Acides gras essentiels",
  "sa_omega3": "Oméga3",
  "sa_omega6": "Oméga6",
  "sa_omega9": "Oméga9",
  "sa_antioxidants": "Antioxydants",
  "sa_circulation": "Circulation",
  "sa_stomach": "Estomac",
  "sa_pancreas": "Pancréas",
  "sa_intestine": "Intestin",
  "sa_detoxification": "Détoxication",
  "sa_jointBonesMuscles": "Arti/Os/Muscles",
  "sa_neuromediators": "Neuromédiateurs",
  "sa_cathecolamine": "Dopamine",
  "sa_indolamine": "Sérotonine",
  "sa_acidBaseBalance": "Equil.Acide/Base",
  "sa_hormonalBalance": "Equil.Hormo",
  "sa_aesthetic": "Esthétique",
  "sa_thinning": "Amincissement",
  "sa_aging": "Viellissement",
  "sa_mgEnergy": "Energie MG",
  "sa_vitaminMinerals": "Vit./Minér",
  "sa_inflammation": "Inflammation",
  "sa_meatAndColdCuts": "Mangez-vous des produits animaux (viande et/ou charcuterie) plus de 5 fois par semaine?",
  "sa_industrialDishes": "Mangez-vous des plats industriels (congelés, en conserves ou plats préparés) plus d'une fois par jour?",
  "sa_cowMilk": "En général, consommez-vous du lait de vache et/ou des dérivés (yaourt, fromages, glace, crème dessert...) deux fois par jour ou plus ?",
  "sa_oilyFish": "En général, mangez-vous du poisson gras (saumon, maquereau, anchois, sardine...) frais ou en conserve, deux fois par semaine ?",
  "sa_oliveOil": "Consommez-vous de l'huile d’olive vierge et bio tous les jours ?",
  "sa_otherOils": "Quotidiennement, utilisez-vous d'autres huiles vierges et bio (colza, noix, lin, cameline...)?",
  "sa_pastries": "Mangez-vous pâtisseries, viennoiseries, tartes salées, pâtes feuilletées, ..., plus de 3 fois par semaine?",
  "sa_fastFoods": "Mangez-vous à l'extérieur (Restaurations rapides, cantines, restaurants) plus de 3 fois par semaine?",
  "sa_sweets": "Mangez-vous plus de 2 biscuits, gâteaux, barres de céréales, confiseries, chocolat, boissons sucrées, ..., par jour?",
  "sa_fruitsAndVegetables": "Mangez-vous chaque jour plusieurs fruits et légumes crus de saison?",
  "sa_seasonalVegetables": "Mangez-vous chaque jour des légumes de saison cuits à la vapeur ou cuisson basse température, (hors micro-ondes)?",
  "sa_organicProducts": "Mangez-vous, de préférence, des produits biologiques ?",
  "sa_largerMeal": "Mangez-vous plus le soir que le midi?",
  "sa_eatRegularly": "Mangez-vous dans le calme (sans TV), à heures régulières?",
  "sa_skipMainMeals": "Sautez-vous régulièrement l’un des 3 repas (petit-déjeuner, déjeuner, dîner)?",
  "sa_lowCalorieDiet": "Suivez-vous un régime hypocalorique, avec restriction alimentaire?",
  "sa_drinkWater": "Buvez-vous plus d’1 litre d’eau de source par jour ?",
  "sa_intestinalTransit": "Avez-vous des problèmes de transit intestinal (diarrhée, constipation, ballonnement) ?",
  "sa_bigMealAndHeadache": "Souffrez-vous de maux de tête après un repas copieux ?",
  "sa_whiteTongue": "Votre langue est-elle souvent blanche et/ou une haleine chargée ?",
  "sa_slowDigestion": "Avez-vous une digestion lente, difficile ou des ballonnements ?",
  "sa_gumsSensitive": "Saignez-vous régulièrement des gencives, sont-elles sensibles ?",
  "sa_chilly": "Êtes-vous frileux(se) ?",
  "sa_sweetProducts": "Avez-vous envie de consommer des produits sucrés (plusieurs fois par jour) ?",
  "sa_irritableOrTiredSkippingMeal": "Est-ce que sauter un repas vous rend plus fatigué(e) ou irritable ?",
  "sa_decreasedAttentionBeforeMeal": "Avant les repas, remarquez-vous une baisse de concentration ou un changement d'humeur ?",
  "sa_dizziness": "Avez-vous régulièrement des vertiges, nausées ou maux de tête ?",
  "sa_significantFatMassInAbdominalArea": "Constatez-vous une localisation de masse grasse importante au niveau abdominal ?",
  "sa_intestinalPain": "Vous plaignez-vous fréquemment de douleurs intestinales (colites), ballonnements et flatulences ?",
  "sa_drowsiness": "Au quotidien, souffrez-vous de somnolence ou d’inconfort après les repas ?",
  "sa_cravings": "Ressentez-vous régulièrement des « fringales » avant les repas ?",
  "sa_drySkin": "Votre peau est-elle sèche (y compris eczéma, engelures, crevasses...), rugueuse ou à tendance acnéique ?",
  "sa_entDisorders": "Souffrez-vous régulièrement de troubles ORL (rhumes, sinusites, otites) ou d’allergies diverses (respiratoires ou alimentaires) ?",
  "sa_loseHair": "Constatez-vous une perte de cheveux importante ou sont-ils dévitalisés ?",
  "sa_cigarettesAndAlcohol": "Fumez-vous plus de 5 cigarettes par jour ou buvez-vous plus d’un verre d’alcool fort ou plus de 2 verres de vin chaque jour ?",
  "sa_skinDiscomfort": "Ressentez-vous un inconfort cutané : picotements, tiraillements, démangeaisons, vergetures ?",
  "sa_nocturnalAwakening": "Vous réveillez-vous régulièrement entre 1h et 3h du matin ?",
  "sa_lackOfRestfulSleep": "Ressentez-vous un manque de sommeil réparateur (difficultés d’endormissement, sommeil agité, réveils nocturnes) ?",
  "sa_stress": "Vous sentez-vous stressé(e) par votre vie quotidienne ou surmené(e) par des soucis personnels ou professionnels ?",
  "sa_nervous": "Êtes-vous actuellement particulièrement nerveux(se), anxieux(se) ou fatigué(e) d’un point de vue psychologique ?",
  "sa_depressed": "Avez-vous l’impression d’avoir moins d’intérêt ou de plaisir qu’avant ?",
  "sa_stimulants": "Avez-vous besoin de stimulants tels que le café, l’alcool, le tabac ?",
  "sa_memorizationDifficulties": "Avez-vous des difficultés de mémorisation ou des troubles de la concentration ?",
  "sa_energyLoss": "Ressentez-vous régulièrement une perte d’énergie ou un épuisement nerveux ?",
  "sa_cholesterol": "Avez-vous un excès de cholestérol, de triglycérides, d’acide urique ou des calculs ?",
  "sa_skinSpots": "Avez-vous l’impression de vieillir plus vite que votre âge : rides, manque d’élasticité de la peau, tâches brunes…?",
  "sa_whiteSpotsOnTheNails": "Avez-vous des tâches blanches sur les ongles ou sont-ils striés, cassants, mous ?",
  "sa_chronicMusclePain": "Avez-vous régulièrement des douleurs musculaires?",
  "sa_decalcification": "Souffrez-vous de décalcification (ostéoporose) ?",
  "sa_backAche": "Souffrez-vous régulièrement de mal au dos ?",
  "sa_decreasedJointMobility": "Constatez-vous une mobilité musculaire réduite ou des douleurs dues à l’arthrose ?",
  "sa_cramps": "Souffrez-vous régulièrement de crampes, courbatures ou tendinites ?",
  "sa_stiffMusclesAndJoints": "Est-ce que vos muscles et vos articulations sont ankylosés le matin, au réveil ?",
  "sa_premenstrualDisorders": "Êtes-vous sujette à des troubles prémenstruels (rétention d’eau, prise de poids, maux de tête, douleurs mammaires, irritabilité...) ?",
  "sa_hormonalContraceptive": "Utilisez-vous un contraceptif hormonal (pilule, stérilet) ?",
  "sa_irregularPeriods": "Vos règles sont-elles irrégulières ?",
  "sa_menopause": "Avez-vous des bouffées de chaleur, une prise de poids liées à la péri-ménopause ou à la ménopause ?",
  "sa_whiteDischarge": "Avez-vous des pertes blanches, des brûlures, des démangeaisons vaginales ?",
  "sa_needToUrinate": "Urinez-vous de façon plus fréquente ?",
  "sa_hyperactiveTendency": "Êtes-vous hyperactif, sujet au stress ?",
  "sa_adenoids": "Êtes-vous sujet aux végétations (si vous n'avez pas été opéré) ?",
  "sa_periodOfIntenseGrowth": "Êtes-vous en période de croissance intense ?",
  "sa_breathless": "Êtes-vous essoufflé(e) en montant les escaliers ? Suivez-vous un traitement pour l’hypertension ou des troubles cardio-vasculaires ?",
  "sa_dentalFillings": "Avez-vous actuellement des plombages ou des couronnes métalliques en bouche ?",
  "sa_pollutedUrbanEnvironment": "Vivez-vous dans un environnement urbain pollué ou êtes-vous en contact quotidiennement avec des produits chimiques (ex : peintre, coiffeur, blanchisseur...) ?",
  "sa_viewHasChanged": "Avez-vous constaté ces derniers mois une fatigue oculaire ou vous a-t-on diagnostiqué des pathologies oculaires : DMLA, cataracte…) ?",
  "sa_prematurelyAging": "Avez-vous l'impression de vieillir prématurément ?",
  "sa_asthma": "Souffrez-vous fréquemment d’asthme ou de troubles cutanés tels qu’eczéma, psoriasis, dermatite atopique ?",
  "sa_noPhysicalActivity": "Pratiquez-vous peu ou pas du tout d’activité physique ou marche rapide (moins d’1h30 par semaine) ?",
  "sa_antibiotic": "Avez-vous suivi un traitement antibiotique prolongé (plus d’un mois) ou répété au cours des 6 derniers mois ?",
  "sa_analItching": "Souffrez-vous régulièrement de démangeaisons anales ou génitales ?",
  "sa_cavities": "Avez-vous régulièrement des caries, des gingivites, des déchaussements dentaires ou des aphtes ?",
  "sa_urinaryTractInfections": "Ressentez-vous des brûlures à la miction ou souffrez-vous d’infections urinaires à répétition ?",
  "sa_dullHair": "Avez-vous les cheveux et/ou le teint ternes ?",
  "sa_spasmophilia": "Avez-vous des signes de spasmophilie ou de tétanie ?",
  "sa_medicalTreatment": "Suivez-vous un traitement médicamenteux de longue durée (autre qu’homéopathie ou nutrithérapie) ?",
  "sa_excessiveExposureToTheSun": "Vous exposez-vous fréquemment au soleil ? Avez-vous pris de nombreux coups de soleil dans votre enfance ?",
  "sa_breakfast": "Petit-déjeuner",
  "sa_breakfastWhatTime": "À quelle heure mangez-vous le petit-déjeuner ?",
  "sa_breakfastDescription": "Quels sont les aliments et boissons qui composent le petit-déjeuner ? (soyez précis)",
  "sa_lunch": "Déjeuner",
  "sa_lunchWhatTime": "À quelle heure mangez-vous le déjeuner ?",
  "sa_lunchDescription": "Quels sont les aliments et boissons qui composent le déjeuner ? (soyez précis)",
  "sa_dinner": "Dîner",
  "sa_dinnerWhatTime": "À quelle heure mangez-vous le dîner ?",
  "sa_dinnerDescription": "Quels sont les aliments et boissons qui composent le dîner ? (soyez précis)",
  "sa_snack": "Collation",
  "sa_snackDescription": "Quels sont les aliments et boissons qui composent votre collation ? (Précisez les heures)",
  "sa_betweenMeals": "Entre les repas ? (grignotage)",
  "sa_betweenMealsDescription": "Si vous mangez entre les repas, veuillez préciser les heures, les aliments et boissons",
  "sam_ifDiarrhea": "Si diarrhée",
  "sam_ifConstipation": "Si constipation",
  "sam_ifAcidifiedGround": "Si terrain acidifié",
  "sam_elementsDealtWithInASecondStep": "Les éléments ci-dessous seront traités dans un deuxième temps (une autre anamnèse pourra être à nouveau demandée)",
  "sam_ifRelatedToWeightLoss": "Si lié à une perte de poids",
  "sam_addAcerolaIfJoinProblem": "Si problème articulaire, mal de dos, arthrose : ajouter Acérola",
  "sam_ifYouSuspectAThyroidProblem": "Si un problème de thyroïde est suspecté",
  "sam_shouldNotBeLinkedWithAnticoagulants": "Attention: ne doit pas être associé à des anticoagulants",
  "sam_ifCholesterol": "Si cholestérol",
  "sam_noFishConsumption": "Parce qu'aucune consommation de poisson",
  "sam_okIfIronDeficiencyExPeriodOfMenstruation": "Ok si carence en fer (ex: période de menstruation)",
  "sai_charcoal": "Nettoie les intestins",
  "sai_aloe": "Santé gastro-intestinale",
  "sai_lTyro": "Production de dopamine",
  "sai_mind": "Anti-stress / Anti-dépresseur / Tonifiants",
  "sai_noctivance": "Mélatonine",
  "sai_packGestionDuSommeil": "Sérotonine et Mélatonine",
  "sai_packImmunite": "D3 / ZINC / NAC / ORIGOLIO",
  "sai_cardiomega": "Oméga 3",
  "sac_changeFeed": "Changer d'alimentation",
  "sai_phenol": "Antioxydants",
  "sai_alca": "Rééquilibrer la balance acide/base",
  "sai_packGestionDuPoids": "Omega calanus / Verda T Vita",
  "sai_coq10": "Coenzyme Q10",
  "sai_d3": "Vitamine D3",
  "sai_mgTop": "Magnésium",
  "sac_bloodTest": "Demander une prise de sang",
  "sac_reduceConsumptionOfAnimalProducts": "Réduire la consommation de produits animaux",
  "sac_reduceMilkConsumption": "Réduire la consommation de lait",
  "sac_consumeMoreOilUnheated": "Consommer plus d'huile (non chauffée)",
  "agsReport": "Le rapport d'Nutri-logics",
  "displayAgsReport": "Afficher le rapport d'Nutri-logics",

  //PRO
  "of": "de",
  "Jan": "Jan",
  "Feb": "Fév",
  "Mar": "Mar",
  "Apr": "Avr",
  "May": "Mai",
  "Jun": "Juin",
  "Jul": "Juil",
  "Aug": "Aou",
  "Sep": "Sep",
  "Oct": "Oct",
  "Nov": "Nov",
  "Dec": "Déc",
  "admin": "Admin",
  "logistic": "Logistique",
  "director": "Directeur",
  "director_plural": "Directeurs",
  "manager": "Manager",
  "manager_plural": "Managers",
  "coach": "Coach",
  "coach_plural": "Coachs",
  "consultant": "Consultant",
  "consultants": "Consultants",
  "consultant_plural": "Consultants",
  "delegate": "Délégué",
  "delegate_plural": "Délégués",
  "customer": "Client",
  "customer_plural": "Clients",
  "prospect": "Prospect",
  "prospect_plural": "Prospects",
  "dashboard": "Tableau de bord",
  "commissions": "Commissions",
  "wallet": "Portefeuille",
  "sales": "Ventes",
  "network": "Réseau",
  "clients": "Clients",
  "thisYear": "Cette année",
  "thisMonth": "Ce mois-ci",
  "byYear": "Par année",
  "moreSalesButton": "Montrer plus de ventes",
  "directSales": "Ventes directes",
  "directSalesMin": "VD",
  "directRecurringSales": "Ventes directes (récurrences)",
  "networkSales": "Ventes réseau",
  "networkSalesMin": "VR",
  "networkRecurringSales": "Ventes réseau (récurrences)",
  "turnoverByCareer": "Chiffre d'affaire par carrière",
  "averageTurnoverByActivePro": "Chiffre d'affaire moyen par pros actifs",
  "directRecruitments": "Recrutements directs",
  "directRecruitmentsMin": "RD",
  "networkRecruitments": "Recrutement réseau",
  "networkRecruitmentsMin": "RR",
  "directBestSellers": "Meilleurs vendeurs en direct",
  "directBestRecruiters": "Meilleurs recruteurs en direct",
  "networkBestSellers": "Meilleurs vendeurs réseau",
  "networkBestRecruiters": "Meilleurs recruteurs réseau",
  "directProductsSold": "Produits vendus en direct",
  "directPacksSold": "Packs vendus en direct",
  "networkProductsSold": "Produits vendus réseau",
  "networkPacksSold": "Packs vendus réseau",
  "selectOtherMonth" : "Cliquez sur la date pour la modifier",
  "downloadCreditNote" : "Téléchargez la note de crédit",
  "DCE": "CDE",
  "bankAndLogisticCharges": "Frais bancaires et logistique",
  "DCEExplanation": "Montant commission de vente directe exclue",
  "newSaleMessage": "Vente dans la boutique de votre {{role}} {{name}} : {{amount}}",
  "newDirectSaleMessage": "Vente dans votre boutique : {{amount}}",
  "refundedSaleMessage": "Vente remboursée : {{amount}}",
  "newProPurchaseMessage": "Achat pro : {{amount}}",
  "newProPurchaseMessageFromNetwork": "Achat pro de votre {{role}} {{name}} : {{amount}}",
  "saleDetails": "Détails de la vente",
  "notCommissioned": "Non commissionné",
  "bank": "Banque",
  "makeTransfer": "Effectuer un virement",
  "makeTransferDescription": "Le montant total de vos commissions sera transféré sur votre compte bancaire.",
  "notEnoughTransferError": "Vous devez disposer d'au moins 100 {{currency}} sur votre compte pour effectuer un virement.",
  "maxAmount": "max: {{amount}}",
  "transferRegistered": "Votre demande de virement a été enregistrée. Il peut avoir un delai de quelques jours avant que celui-ci n'arrive sur votre compte.",
  "transferAmount": "Transférer {{amount}} sur votre compte bancaire ?",
  "transfers": "Virements",
  "downloadCreditNotesDescription": "Séléctionnez la note de crédit à télécharger.",
  "number": "Numéro",
  "commissionRefund": "Commission remboursée",
  "purchaseRefund": "Achat remboursé",
  "commission": "Commission",
  "purchase": "Achat",
  "transfer": "Transfert",
  "networkAdmin": "Admin réseau",
  "statistics": "Statistiques",
  "customersAdmin": "Admin clients",
  "structure": "Structure",
  "seller": "Sales manager",
  "requests": "Demandes",
  "amount": "Montant",
  "currency": "Devise",
  "evolution": "Évolution",
  "selectedRole": "Rôle sélectionné",
  "networkEvolution": "Évolution du réseau",
  "networkStructure": "Structure du réseau",
  "downloadImage": "Télécharger en image",
  "downloadPDF": "Télécharger en PDF",
  "customers": "Clients",
  "addCustomer": "Ajouter un client",
  "createCustomer": "Créer le client",
  "updateCustomer": "Modifier le client",
  "updateCustomerShippingAddress": "Modifier l'adresse de livraison du client",
  "deleteTheProspect": "Supprimer le prospect",
  "prospectDeleted": "Prospect supprimé",
  "importExistingCustomer": "Ce client existe déjà sur le site, voulez vous importer sa fiche ? Attention, les données existantes ne sont peut être pas à jour.",
  "customerImportation": "Importation d'un compte client",
  "importation": "Importation",
  "name": "Nom",
  "date": "Date",
  "removedAt": "Supprimé(e) le",
  "generalInformation": "Informations générales",
  "applicationDescription": "Candidature nouveau délégué",
  "buttonWillAppearAfterOneDay": "Les boutons d'acceptation ou de refus apparaîtront au bout d'un jour si le consultant en charge n'a aucune activité",
  "buttonOnlyForCoach": "L'acceptation de la demande est réservée à la position de coach ou plus.",
  "buttonCoachAlertedByTheRequest": "Encore un peu plus de patience, votre coach a été alerté et étudiera cette demande.",
  "accept": "Accepter",
  "decline": "Décliner",
  "confirmProfilingRequest": "Si l'utilisateur semble éligible pour devenir un délégué Nutri-logics, cliquez sur OK pour accepter la demande. Un e-mail de bienvenue sera envoyé au nouveau délégué.",
  "declineProfilingRequest": "Si l'utilisateur ne semble pas être éligible pour devenir un délégué Nutri-logics, cliquez sur OK pour refuser la demande.",
  "requestApproved": "La demande a été approuvée",
  "requestDeclined": "La demande a été refusée, vous disposez de 7 jours pour modifier la décision. Après cette date, la demande sera supprimée.",
  "update": "Mettre à jour",
  "createOrder": "Créer une commande",
  "pricesExclTaxes": "Les prix affichés sont hors TVA",
  "exclTaxes": "HT",
  "addProductToOrder": "Ajouter un produit à la commande",
  "addProductToPrescription": "Ajouter un produit à la prescription",
  "noOrder": "Aucune commande",
  "sendOrderToClient": "Envoyer la commande au client",
  "prescriptionSent": "Prescription envoyée",
  "sendPrescriptionToClient": "Envoyer la prescription au patient",
  "prescription": "Prescription",
  "prescriptions": "Prescriptions",
  "searchProduct": "Rechercher un produit",
  "orderSent": "Commande envoyée",
  "requestSent": "Demande envoyée",
  "requestRemoved": "Demande supprimée",
  "searchCustomer": "Rechercher un client",
  "noCustomerFound": "Aucun client trouvé",
  "customerRedirectionRequestError": "Nous ne trouvons pas la demande, veuillez contacter le délégué responsable.",
  "entrustCustomerToNutritionConsultant": "Confier le client à un conseiller en nutrition",
  "entrustCustomerToNutritionConsultantDescription1": "Vous êtes sur le point de confier votre client à un conseiller en nutrition. Cela permettra à votre client d'avoir un accompagnement professionnel adapté à ses besoins.",
  "entrustCustomerToNutritionConsultantDescription2": "Cette aide est gratuite tant que vous n'êtes pas certifié. <1/>Après certification, vous aurez le choix entre récupérer votre client ou payer le service à hauteur de 5% de vos commissions.",
  "entrustCustomerTo": "Confier le client à {{name}}.",
  "waitingRequest": "Demande en attente",
  "validated": "Validé",
  "customerRedirectionDefaultMessage": "Mon client désire une consultation, pouvez-vous le prendre en rendez-vous ?",
  "confirmCustomerRequest" : "Prendre en charge le client",
  "declineCustomerRequest" : "Décliner la prise en charge du client",
  "customerRedirectionRequestDeclined": "La demande a été refusée.",
  "customerRedirectionRequestDeclinedDescription": "Vous avez refusé la demande de {{consultant}} de prise en charge du client {{customer}}.",
  "customerRedirectionRequestAccepted": "La demande a été acceptée.",
  "customerRedirectionRequestAcceptedDescription": "Vous avez accepté la demande de {{consultant}} de prise en charge du client {{customer}}. Nous vous prions de prendre contact avec lui dans les 72h maximum pour programmer un rendez-vous de consultation. En plus du prix de votre consultation, vous recevrez une commission de 5% sur l'intégralité de ses futures commandes jusqu'à ce que le consultant obtienne son certificat.",
  "customerAddedToYourClientList": "Le client a été ajouté à la liste de vos clients déjà existants.",
  "sendBackTheCustomerToTheDelegate": "Renvoyer le client au délégué",
  "sendBackTheCustomerToTheDelegateDescription": "Vous êtes sur le point de renvoyer le client au délégué. Cette opération est irréversible.",
  "takeTheCustomerBack": "Récuperer le client",
  "youNeedToHaveCertificationToAccessAnamnesis": "Vous devez être conseiller en nutrition pour accéder à la section anamnèse.",
  "ifYouAreAlreadyANutritionConsultant": "Si vous êtes déjà conseiller en nutrition",
  "ifYouAreNotAlreadyANutritionConsultant": "Si vous n'êtes pas encore conseiller en nutrition",
  "redirectYourCustomerToANutritionConsultant": "Redirigez votre client vers un conseiller en nutrition de votre choix en cliquant ici. Le client restera dans votre réseau (vos commissions restent inchangées) mais le conseiller en nutrition le prendra en charge.",
  "redirectToANutritionConsultant": "Redirection vers un conseiller en nutrition",
  "getCustomerBackDescription": "Récupérer le client ?",
  "customerIsNotLinkedAnymore": "Le client n'est plus lié.",
  "thankYouForAnswering": "Merci d'avoir répondu à cette demande.",
  "goToClientAccount": "Aller au compte du client",
  "requestAnamnesis": "Demander une anamnèse",
  "needToHaveCertificationAnamnesis": "La certification est obligatoire pour réaliser l'analyse.",
  "writeNote": "Écrire une note privée",
  "notesArePrivate": "Les notes sont confidentielles",
  "showOlderNotes": "Afficher les notes plus anciennes",
  "noteAdded": "Note ajoutée",
  "noteRemoved": "Note supprimée",
  "sendEmail": "Envoyer un email",
  "sendEmailNutritionConsultant": "Envoyer un email au conseiller en nutrition",
  "sendEmailDelegate": "Envoyer un email au délégué",
  "call": "Appeler",
  "callNutritionConsultant": "Appeler le conseiller en nutrition",
  "callDelegate": "Appeler le délégué",
  "notInChargeShowButtonsAnyway": "Vous n'êtes pas responsable du client. Afficher quand même les actions?",
  "watchProfilingTitle": "Voir le profilage",
  "watchAnamnesis": "Voir l'anamnèse",
  "notes": "Notes",
  "writeYourNoteHere": "Ecrivez votre note ici",
  "delete": "Supprimer",
  "needSupportForAnamnesis": "Besoin d'un support pour lire cette anamnèse ?",
  "askAgsAnamnesis": "Demander de l'aide à Nutri-logics pour lire cette anamnèse. Vous retrouverez les messages du support via l'onglet \"notes\".",
  "anamnesisAgsSupportRequested": "Support demandé : Nutri-logics vous écrira très prochainement via l'onglet \"notes\".",
  "supportRequested": "Support demandé!",
  "resendIdentifiers": "Renvoyer les identifiants par e-mail",
  "payTheOrder": "Payer la commande",
  "sentPaymentEmail": "Renvoyer l'e-mail de paiement",
  "customerPaymentEmailSent": "Email de commande envoyé",
  "emailSent": "Email envoyé",
  "orderRecommendationDeleteDescription": "Supprimer la recommendation",
  "deleteRecommendation": "Supprimer la recommendation",
  "deletedRecommendationDescription": "La recommandation a été supprimée avec succès.",
  "belongsToNetwork": "Appartient au réseau",
  "shippingFees": "Frais de port",
  "allowFreeShipping": "Les frais de port sont offerts à partir de 60€ et ventilés dans le réseau",
  "disallowFreeShipping": "Les frais de port ne sont pas offerts et seront payés par le client",
  "allowFreeShippingDescription": "Si vous décidez de proposer la livraison, le prix sera réparti sur l'ensemble du réseau concerné.",
  "clientsCanSearchYouBy": "Les clients peuvent vous rechercher via:",
  "yourNameAndFirstName": "Votre nom et prénom.",
  "yourCompanyName": "Le nom de votre entreprise.",
  "companyNeeded": "Vous n'avez pas d'entreprise enregistrée sur le site.",
  "yourCityOrPostalCode": "Votre ville ou code postal.",
  "cannotBeChanged": "Cette option ne peut pas être changée",
  "youDoNotHaveYourIdentifier": "Vous n'avez pas encore d'identifiant, veuillez le configurer en cliquant ici",
  "searchSettingsDescription": "Ces paramètres vous permettent de configurer la manière dont les clients vous trouveront sur le site.<1/>Si vous décidez de ne pas autoriser la recherche via votre prénom et votre nom ou via votre entreprise, ceux-ci n'apparaîtront pas sur le site.",
  "directLink": "Identifiant",
  "yourDirectLink": "Votre lien",
  "identifier": "Identifiant",
  "yourIdentifier": "Votre identifiant",
  "todoList": "Rappels",
  "createIdentifier": "Créez un identifiant",
  "youDoNotHaveProIdentifierYet": "Vous n'avez pas encore d'identifiant pro. Vous pouvez en créer un en cliquant ici.<1/>Cet identifiant est utilisé par les clients pour accéder à votre boutique et peut également vous donner la possibilité de partager avec des liens pro sur les réseaux sociaux ou par email.",
  "identifierDescription": "Créez et personnalisez votre identifiant. Il s'agit d'<1>un mot unique (pouvant contenir des chiffres) que vous donnerez à vos clients pour accéder à votre boutique.</1><2/> Cet identifiant vous donnera également accès à vos liens professionnels que vous pourrez partager sur les réseaux sociaux ou par mail.",
  "directLinkDescription": "Personnalisez le lien direct vers votre boutique pour le partager facilement. Toute personne disposant de ce lien peut accéder directement à votre boutique sans avoir à trouver votre nom.",
  "directLinkActive": "Lien activé. Votre boutique est accessible depuis votre lien personnalisé",
  "directLinkInactive": "Lien désactivé. Votre boutique n'est pas accessible actuellement via un lien.",
  "copyInClipboard": "Copier dans le presse-papiers",
  "linkCopiedInClipboard": "Lien copié dans le presse-papiers",
  "specialCharactersDirectLinkError": "Seuls les caractères alphanumériques sans accent et les tirets sont autorisés",
  "yourActiveLinks": "Vos liens actifs",
  "general": "Général",
  "displayWallet": "Crédit Nutri-logics",
  "displayWalletOnTopBar": "Afficher le montant du crédit Nutri-logics dans la barre supérieure du site",
  "displayWalletOnTopBarDescription": "Le montant du crédit Nutri-logics sera toujours affiché sur la page \"Commissions > Banque\"",
  "meetings": "Meetings",
  "documentsAndProtocols": "Documents & Protocoles",
  "protocols": "Protocoles",
  "moreProtocolsButton": "Afficher plus de protocoles",
  "searchProtocol": "Rechercher un protocole",
  "searchProtocolsDescription": "Recherchez par mots-clés ou écrivez une phrase pour améliorer les résultats",
  "cantFindProtocol": "Vous ne trouvez pas le protocole associé à votre demande ?",
  "askProtocolToAgs": "Demandez un protocole à Nutri-logics",
  "businessCard": "Carte de visite",
  "beSpecific": "Soyez précis pour que nous puissions vous répondre précisement",
  "faqSpecificToNutrition": "Cette FAQ est spécifique à la nutrition, pour toute autre question, merci d'écrire à info@ags-lab.com",
  "request": "Demande",
  "protocolSentToNutri-logics": "La demande a été envoyé à Nutri-logics. Vous serez averti par e-mail lorsqu'une réponse sera ajoutée.",
  "office": "Etablissement",
  "displayYourOffice": "Afficher votre établissement",
  "officeUpdateText": "Ces informations sont publiques et seront affichées sur le site et sur les moteurs de recherche.",
  "specialities": "Spécialités",
  "description": "Description",
  "descriptionPro": "Description (Pro)",
  "openingTime": "Horaires d'ouverture",
  "services": "Prestations",
  "serviceName": "Nom de la prestation",
  "priceByCurrency": "Prix en {{currency}}",
  "addMoreService": "Ajouter plus de prestations",
  "saveOfficeInfo": "Mettre à jour l'établissement",
  "coverPicture": "Image de couverture",
  "profilePicture": "Image de profil",
  "updateCoverPictureButton": "Modifier la photo de couverture",
  "updateProfilePictureButton": "Modifier la photo de profil",
  "updateInfoButton": "Modifier les infos",
  "dropImageInfo": "Faite glisser une image dans le cadre ou cliquez ici",
  "cancel": "Annuler",
  "fileRemoved": "{{fileName}} a été supprimé",
  "fileAdded": "{{fileName}} a été ajouté",
  "fileCannotBeenImported": "{{fileName}} ne peut pas être importé",
  "officeNotDisplayedDescription": "L'établissement n'est pas affiché sur la carte et les moteurs de recherche.",
  "partnerOfficeNotDisplayedDescription": "L'établissement n'est pas affiché en ligne.",
  "officeDisplayedDescription": "L'établissement est affiché sur la carte et les moteurs de recherche.",
  "partnerOfficeDisplayedDescription": "L'établissement est affiché en ligne et visible par les moteurs de recherche.",
  "officeDisplayedQualificationDescription": "Vous devez avoir une certification de conseiller en nutrition pour être affiché sur la carte.",
  "changeVisibility": "Changer la visibilité",
  "chooseAsANutritionConsultant": "Choisir comme conseiller en nutrition",
  "website": "Site internet",
  "saved": "Sauvegardé",
  "proAccount": "Compte pro",
  "generalInfo": "Informations générales",
  "bankAccountHolder": "Titulaire du compte en banque",
  "iban": "IBAN",
  "bic": "BIC",
  "membership": "Adhésion / Cotisation annuelle",
  "payMembershipWithWalletButton": "Renouveler l'adhésion avec le crédit Nutri-logics",
  "payMembershipWithAnotherPaymentMethodButton": "Renouveler l'adhésion avec une autre méthode de paiement",
  "payMembershipByWalletDescription": "Renouveler l'adhésion ? {{amount}} seront déduits de vos commissions.",
  "membershipDaysRemaining": "Il vous reste {{days}} jours avant l'arret de vos services.",
  "servicesInactive": "Vos services sont inactifs.",
  "membershipAccessProvidesDescription": "L'adhésion permet d'acceder aux services suivants :",
  "accessibilityOfYourStore": "L'accessibilité de votre boutique.",
  "accessibilityOfYourStoreDescription": "Votre boutique est accéssible via le module de recherche des conseillers et via votre lien pro.",
  "professionalPurchasing": "Achat professionels.",
  "professionalPrices" : "Achetez dans votre boutique avec vos prix professionels.",
  "accessTrainingDocumentsAndProtocols" : "Accès aux formations, documents et protocoles.",
  "accessNutri-logicsTraining" : "Accès complet aux formations, certifications, documents et protocoles Nutri-logics.",
  "accessTrainingAndConferences" : "Formations et conférences",
  "accessNutri-logicsTrainingAndConferences" : "Accès complet et illimité aux formations et conférences depuis votre espace pro.",
  "displayOnTheMap" : "La parution sur la carte des conseillers en nutrition.",
  "eachEstablishmentIsReferenced" : "Chaque établissement est référencé sur les moteurs de recherche et visible pour tous les utilisateurs.",
  "payMembership": "Payer l'adhésion / cotisation annuelle",
  "membershipEndAt": "Adhésion active jusqu'au {{date}}",
  "membershipExpired": "Adhésion expirée",
  "remindMeLater": "Me le rappeler plus tard",
  "faqNutrition": "FAQ Nutrition",
  "moreQuestionsButton": "Afficher plus de questions",
  "searchQuestion": "Rechercher une question",
  "searchQuestionsDescription": "Recherchez par mots-clés ou écrivez une phrase pour améliorer les résultats",
  "cantFindQuestion": "Vous ne trouvez pas la question associée à votre demande ?",
  "askQuestionToAgs": "Poser une question à Nutri-logics",
  "privateRequest": "Il s'agit d'une demande privée",
  "myQuestions": "Mes questions",
  "confirm": "Confirmer",
  "answerAdded": "Réponse ajoutée",
  "sending": "Envoie en cours",
  "questionSentToNutri-logics": "Question envoyée à Nutri-logics",
  "provideAnswer": "Envoyer une réponse",
  "noQuestionsFound": "Pas de question trouvée",
  "submit": "Envoyer",
  "status": "Status",
  "partner": "Partenaire",
  'level': 'Niveau {{lvl}}',
  "partners": "Partenaires",
  "addPartner": "Ajouter un nouveau partenaire",
  "partnerOf": "Partenaire de",
  "updatePartner": "Modifier le partenaire",
  "createPartner": "Créer le partenaire",
  "partnerDescription": "La carrière partenaire désigne toutes les institutions tel que : les pharmacies, les centres esthétiques, les magasins spécialisés bio, les centres de bien-être, les centres sportifs et tous les professionnels de la santé non nutritionnistes. Le partenaire ne rentre pas dans la carrière conventionnelle. <1/><2>Si vous avez besoin d'aide, n'hésitez pas à vous référer auprès de votre manager ou directeur avant tout nouveau partenariat.</2>",
  "partnerFromAnotherDelegateDescription": "Si vous avez créé un partenaire pour un autre délégué du réseau, la demande apparaîtra dans le compte du délégué.",
  "partnerDelegateDescription": "Pour créer un partenariat, vous devez vous référer auprès de votre manager.",
  "partnershipRequest": "Demande de partenariat",
  "removeRequest": "Supprimer la demande",
  "resendRequest": "Renvoyer la demande",
  "partnershipTitle": "Les avantages du partenariat Nutri-logics :",
  "partnershipP1": "La gamme de complément Nutri-logics aux prix professionels.",
  "partnershipP2": "Une boutique dédiée pour vos clients.",
  "partnershipP3": "Une anamnèse en ligne.",
  "partnershipP4": "Les documents et materiels.",
  "partnershipP5": "Les formations et conférences (soumis à une adhésion de 80€).",
  "partnershipP6": "Un espace pro (logistique, factures, gestion clients, etc...).",
  "partnershipConclusion1": "Une fois le partenariat validé, vous allez être redirigé vers une page de création de votre mot de passe. Vous pourrez ensuite vous connecter avec votre email et le mot de passe qui vous aurez choisi.",
  "partnershipConclusion2": "N'hésitez pas a contacter votre référent pour obtenir une explication détaillée des outils liés à votre compte professionel.",
  "validatePartnership": "Valider le partenariat",
  "partnershipValidated": "Partenariat validé",
  "challenge": "Challenge",
  "current": "Courrant",
  "objective": "Objectif",
  "objectiveMin": "Obj",
  "done": "Accompli",
  "todo": "À faire",
  "giftCards": "Cartes cadeaux",
  "giftCard": "Carte cadeau",
  "code": "Code",
  "sentAt": "Envoyé le",
  "usedAt": "Utilisé le",
  "convertGiftCardDescription": "Acheter une carte cadeau de {{amount}}? Le montant sera déduit de vos commissions.",
  "giftCardRecoveryDescription": "Si la carte n'est pas utilisée avant le {{date}}, elle sera à nouveau utilisable.",
  "cardWasNotGivenToACustomer": "La carte n'a pas été donnée à un client",
  "cardHasNotBeenSent": "La carte n'a pas été envoyée",
  "cardHasNotBeenUsed": "La carte n'a pas été utilisée",
  "sendTheGiftCard": "Envoyer la carte cadeau",
  "resendTheGiftCard": "Renvoyer la carte cadeau",
  "offerGiftCard": "Offrir une carte cadeau",
  "giftCardAvailable": "{{count}} disponible",
  "giftCardAvailable_plural": "{{count}} disponibles",
  "noGiftCard": "Il n'y a pas de carte cadeau",
  "noGiftCardAvailable": "Il n'y a pas de carte cadeau disponible",
  "convertAndSendGiftCardDescription": "Acheter une carte cadeau de {{amount}} et l'envoyer ? Le montant sera déduit de vos commissions.",
  'seeUsedGiftCards': "Voir les cartes cadeaux utilisées",
  'seeUnusedGiftCards': "Voir les cartes cadeaux non-utilisées",
  "amountGiftCard": "Carte cadeau de {{count}} {{currency}}",
  "availableForSending": "Disponible pour l'envoi",
  "myTreeChallenge": "Challenge : Mon arbre au Sénégal",
  "recruitmentsChallengeTitle": "Top team",
  "recruitmentsChallengeDescription": "Recrutez sur les mois de juillet, août et septembre au minimum 3 directs. Ceux-ci devront réaliser minimum 500 EUR de chiffre d'affaires personnel pour être validés et la somme totale de 2250 EUR de chiffre d'affaire au 31 octobre 2022.",
  "congratulationsObjectivesCompleted": "Félicitations, vous avez accompli l'objectif avec succès !",
  "treeCountChallengeDescription": "Réaliser 250€ de chiffre d'affaire durant le mois de février",
  "starterBonus1Title": "Bonus de démarrage : Pack Business",
  "starterBonus1Description": "Obtenez un pack business en réalisant 150 € de chiffre d'affaires avant le {{date}}",
  "starterBonus2Title": "Bonus de démarrage : Adhésion",
  "starterBonus2Description": "Obtenez un an d'adhésion en réalisant 500 € de chiffre d'affaires avant le {{date}}",
  "starterBonus3Title": "Bonus de démarrage : Première commande",
  "starterBonus3Description1": "Obtenez un pack offert au choix, tous les deux packs achetés. (maximum 5)",
  "starterBonus3Description2": "Tous les deux packs achetés lors de votre première commande, un pack Nutri-logics au choix est offert. Une fois votre commande effectuée, revenez ici choisir vos packs.",
  "claimYourPrize": "Obtenez votre prix",
  "congratulationsBonusClaimed": "Toutes nos félicitations !!! Votre bonus est validé.",
  "bonusEnded": "Bonus terminé",
  "makeYourFirstOrder": "Effectuez votre première commande",
  "packsBought": "Packs achetés",
  "chooseYourFreePack": "Choisissez votre pack gratuit",
  "claimYourPack": "Obtenez votre pack",
  "claimYourPacks": "Obtenez vos packs",
  "bonusEndAt": "Le bonus se termine le",
  "demoSession": "Session démo",
  "restoreSessionControl": "Mettre fin à la démonstration ?",

  //CAREER
  "career": "Carrière",
  "networkPerformances": "Perfomances du réseau",
  "parent": "Parent",
  "depth": "Profondeur",
  "treeStructure": "Structure -Arbre",
  "listStructure": "Structure -Liste",
  "mapStructure": "Structure -Map",
  "turnover": "Chiffre d'affaire",
  "recruitment": "Recrutement",
  "videoPlayerError": "Désolé, votre navigateur ne prend pas en charge les vidéos intégrées. Obtenez la vidéo sur cette URL : {{url}}",
  "needPreviousSteps": "Vous devez terminer les chapitres précédents pour accéder à ce contenu",
  "previousChapter": "Chapitre précédent",
  "nextChapter": "Chapitre suivant",
  "downloadLesson": "Télécharger ppt cours",
  "videoSeenAndNextChapter": "J'ai vu la vidéo et je passe au chapitre suivant",
  "videoSeenAndSelectCareer": "J'ai vu la vidéo et je séléctionne ma carrière",
  "validateTest": "Valider le test",
  "theNutri-logicsCareer": "La carrière Nutri-logics",
  "theObjectives": "Les objectifs",
  "theObjectivesDescription": "Visionnez la réalisation de vos objectifs en temps réel",
  "editObjectives": "Modifier les objectifs",
  "commitChanges": "Valider les modifications",
  "realized": "Réalisé",
  "realizedMin": "Réa",
  "total": "Total",
  "theStarterKit": "Le kit de démarrage",
  "theDelegatePosition": "La position de délégué",
  "theConsultantPosition": "La position de consultant",
  "nutritionConsultant": "Conseiller en nutrition",
  "nutritionistCoach": "Conseiller en nutrition",
  "nutritionistManager": "Conseiller Nutri-logics",
  "structureCoach": "Structure / Coach",
  "directSellerCoach": "Direct-Seller",
  "theNutritionConsultantPosition": "La position de conseiller en nutrition",
  "theCoachPosition": "La position de coach",
  "theManagerPosition": "La position de manager",
  "theDirectorPosition": "La position de directeur",
  "nutritionist": "Conseiller en nutrition",
  "theCertification": "Certification {{id}}",
  "test": "Test",
  "careerTestLocked": "Vous pouvez re-visionner les documents en attendant de pouvoir essayer à nouveau le test.",
  "comebackAt": "Le test revient {{date}}",
  "youPassedTheTest": "Félicitations, vous avez réussi le test.",
  "youFailedTheTest": "Vous n'avez pas reussi le test. Vous avez validé {{ratio}} question(s)",
  "countCorrectAnswers": "Vous avez {{ratio}} de réussite sur cette question.",
  "correctAndRetry": "Dernière tentative.",
  "correctAndRetry_plural": "Encore {{count}} tentatives.",
  "objectivesToReach": "Pour atteindre la position de {{position}}, vous devez atteindre les objectifs suivants :",
  "positionComingSoon": "Encore un peu de patience, la position de {{position}} arrive bientôt ...",
  "loseDelegatesByChoosingThisPositionInfo": "En choisissant cette position, vos délégués seront confiés à votre coach.",
  "consultantsList": "Liste des consultants :",
  "directSalesToReach": "Atteindre {{amount}} {{currency}} de chiffre d'affaire en vente directe",
  "directOrNetworkSalesToReach": "Atteindre {{amount}} {{currency}} de chiffre d'affaire en vente directe + réseau",
  "directSalesRecurrenceToReach": "Atteindre {{amount}} {{currency}} de récurrence mensuelle en vente directe. (Moyenne calculé sur les 6 mois)",
  "networkSalesToReach": "Atteindre {{amount}} {{currency}} de chiffre d'affaire réseau (max {{amountMax}} {{currency}} par branche)",
  "alreadyCounted": "{{amount}} {{currency}} déjà comptabilisé",
  "haveStructure": "Avoir {{amount}} {{role}} dans votre premier niveau",
  "alreadyHave": "{{amount}} {{role}} actuellement dans votre premier niveau",
  "membershipAndCertification": "Adhésion et certification",
  "youAreInThePositionAndCareer": "Vous occupez actuellement le poste de {{position}} dans la carrière de {{career}}",
  "youAreInThePosition": "Vous occupez le poste de {{position}}",

  "intestineAndConstipationPack": "Le pack transit plus",

  "intestineAndConstipationPackQ1": "De quoi est composé le pack transit plus ?",
  "intestineAndConstipationPackQ1R1": "Transit+ Vita & Meli Vita",
  "intestineAndConstipationPackQ1R2": "Curcu vita & Save rénal",
  "intestineAndConstipationPackQ1R3": "MG Top & Meli Vita",
  "intestineAndConstipationPackQ2": "Qu’est ce que le transit+ vita ?",
  "intestineAndConstipationPackQ2R1": "Un pro biotique",
  "intestineAndConstipationPackQ2R2": "Un anti-biotique naturel",
  "intestineAndConstipationPackQ2R3": "Une coenzyme intestinale",
  "intestineAndConstipationPackQ3": "Quel rôle joue le transit+ Vita ?",
  "intestineAndConstipationPackQ3R1": "Il contribue à améliorer le mouvement péristaltique de l’intestin",
  "intestineAndConstipationPackQ3R2": "Il contribue au processus de digestion, il normalise la mobilité intestinale. Il permet de lutter contre la dysbiose et de réguler le transit intestinal, il améliore la santé gastro-intestinale en réduisant l’inflammation, le stress oxydatif, l’intoxication et il améliore le statut de certaines vitamines produites par les bactéries de l’intestin",
  "intestineAndConstipationPackQ3R3": "Le Transit+ vita contribue au processus de dégradation du bol alimentaire et de réguler la bile, il améliore la digestion et l’appétit",
  "intestineAndConstipationPackQ4": "Quel rôle joue la Méli Vita ?",
  "intestineAndConstipationPackQ4R1": "Méli Vita, participe au bon fonctionnement du système nerveux, antispasmodique (calme ou neutralise les contractions involontaires des muscles)",
  "intestineAndConstipationPackQ4R2": "Méli Vita permet au bol alimentaire d’avoir un PH intestinal parfait",
  "intestineAndConstipationPackQ4R3": "Méli Vita participe à un évacuation rapide des selles",
  "intestineAndConstipationPackQ5": "Le siège d’Nutri-logics est installé ?",
  "intestineAndConstipationPackQ5R1": "Luxembourg",
  "intestineAndConstipationPackQ5R2": "Belgique",
  "intestineAndConstipationPackQ5R3": "France",

  "immunityPack": "Le pack immunité",

  "immunityPackQ1": "De quoi est composée le pack immunité ?",
  "immunityPackQ1R1": "NAC  Vita - Zinc Vita - Vitamine D3 forte Vita - L’Origoglio Vita",
  "immunityPackQ1R2": "NAC Vita – FER Vita – Vitamine B – Spirulina Vita",
  "immunityPackQ1R3": "Nac Vita – MG TOP – Alca Vita – Cuculong’arti",
  "immunityPackQ2": "Qu’est ce que le système immunitaire ?",
  "immunityPackQ2R1": "Première ligne de défense face à une infection",
  "immunityPackQ2R2": "Ensemble des organes de défense du corps",
  "immunityPackQ2R3": "C’est la capacité du corps à faire face à toutes épreuve",
  "immunityPackQ3": "Quel rôle joue le NAC Vita ?",
  "immunityPackQ3R1": "La N-acétyl-L-cystéine (NAC) est une molécule protéinique qui provient de la cystéine phosphorique. Elle est d’une efficacité incroyable dans la détoxification et la fluidité des corps étrangers",
  "immunityPackQ3R2": "La N-acétyl-L-cystéine (NAC) est une forme acétylée d’un acide aminé – la cystéine. Elle s'est avérée très efficace en tant qu’antioxydant, détoxifiant, antimicrobien, mucolytique et anti-inflammatoire",
  "immunityPackQ3R3": "Le NAC est une particule naturelle qui provient tout droit des laboratoires NESTLé mieux connue sous la forme de NIC-NA",
  "immunityPackQ4": "Quel rôle joue le Zinc ?",
  "immunityPackQ4R1": "Cette formule de zinc aminochélaté sous la forme de bisglycinate est la plus biodisponible et la mieux absorbée par l’organisme. Le zinc permet de booster la régénération cellulaire",
  "immunityPackQ4R2": "Le Zinc créée une accélération physiologique qui permet d’augmenter les performances de la personne",
  "immunityPackQ4R3": "Le pack proposé permet une absorption optimal dans la 3ème partie de l’intestin. L’organisme peut ainsi redistribuer les parties du corps carencées. Il augmente la vigilance immunitaire",
  "immunityPackQ5": "Quel rôle joue la vitamine D ?",
  "immunityPackQ5R1": "La vitamine D renforce et capitalise votre système nerveux",
  "immunityPackQ5R2": "La vitamine D renforce et capitalise votre système respiratoire",
  "immunityPackQ5R3": "La vitamine D renforce et capitalise votre système immunitaire",
  "immunityPackQ6": "Quel rôle joue l’Origoglio ?",
  "immunityPackQ6R1": "L’Origoglio, huile d’origan, antibiotique naturel",
  "immunityPackQ6R2": "L’Origoglio huile d’origan, assaisonnement exceptionnel",
  "immunityPackQ6R3": "L’Origoglio , huile d’origan, désinfectant naturel",
  "immunityPackQ7": "Nutri-logics est soumise aux normes ?",
  "immunityPackQ7R1": "Européennes uniquement",
  "immunityPackQ7R2": "Européennes et celles de chaque pays dans lequel elle exerce",
  "immunityPackQ7R3": "Pas d’obligation de norme spécifique",

  "painPack": "Le pack ArtiZen",

  "painPackQ1": "De quoi est composée le pack ArtiZen ?",
  "painPackQ1R1": "MG Top & Curculong’arti",
  "painPackQ1R2": "MG top & Ashwaganda",
  "painPackQ1R3": "MG top & Meli Vita",
  "painPackQ2": "Quelles sont les raisons principales des maux du quotidien?",
  "painPackQ2R1": "Un non respect du principe physiologie/nutrition, une mauvaise alimentation, le vieillissement de l’organisme",
  "painPackQ2R2": "Le climat, principalement le taux d’humidité",
  "painPackQ2R3": "L’oxydation organique",
  "painPackQ3": "Quel rôle joue le MG Top ?",
  "painPackQ3R1": "Le MG Top va stimuler votre cerveau et fournir la 1ère énergie nécessaire au corps. Il va vous booster le matin, créer l’énergie nécessaire à un bon démarrage",
  "painPackQ3R2": "Le MG top dopant naturel, il stimule les neurotransmetteur par induction électromagnétique",
  "painPackQ3R3": "Le MG Top est un complément qui offre un tranquillisant et relaxant naturel",
  "painPackQ4": "Quel rôle joue le curculong’arti ?",
  "painPackQ4R1": "Curculong'arti est à base de Biocurcumax® qui est un extrait breveté de curcuma titré à 95 % curcumine, 100 % naturel. Sa particularité est de contenir également les huiles essentielles du curcuma concentrées, dont la Turmérone, qui joue un rôle de cofacteur et qui améliore l'assimilation digestive des curcuminoïdes. L'acide boswellique et l'harpagophytum quant à eux, jouent un rôle anti-inflammatoire au niveau des articulations",
  "painPackQ4R2": "Curculong'arti est un réparateur de cartilage",
  "painPackQ4R3": "Curculong’arti est à base de curcumine 100% naturelle. Elle a la particularité de contenir des huiles essentielles de curcuma concentrées, dont la Violamérone, qui joue un rôle prépondérant dans la relaxation. L’acide illuoritique et hydrogénétique, ont quant à eux un rôle fondamental dans l’absorption du curcuma",
  "painPackQ5": "Que veut dire Nutri-logics ?",
  "painPackQ5R1": "Accompagnement général de la santé",
  "painPackQ5R2": "Accompagnement global de la santé",
  "painPackQ5R3": "Activité Globale Santé",

  "bellaVitaPack": "Le pack bella vita",

  "bellaVitaPackQ1": "De quoi est composée le pack bella vita ?",
  "bellaVitaPackQ1R1": "Epura Vita & Detox Vita",
  "bellaVitaPackQ1R2": "MG Top et Lipzen Vita",
  "bellaVitaPackQ1R3": "Epura Vita & Alca Vita",
  "bellaVitaPackQ2": "Quelles sont les raisons principales de la difficulté de récupération après une soirée arrosée ?",
  "bellaVitaPackQ2R1": "Une surcharge du foie",
  "bellaVitaPackQ2R2": "Le manque de sommeil",
  "bellaVitaPackQ2R3": "La mauvaise qualité de l’alimentation",
  "bellaVitaPackQ3": "Quel rôle joue le Epura Vita ?",
  "bellaVitaPackQ3R1": "Epura Vita est une formulation à base de 5 plantes et super aliments extrêmement importants pour le processus de la détoxification et pour le bon fonctionnement des organes d’élimination.",
  "bellaVitaPackQ3R2": "Epura vita est une formation de 3 plantes radi noir pissenlit et artichaut qui pour objectif d’aider les organes à éliminer les toxines.",
  "bellaVitaPackQ3R3": "Epura vita est un ensemble qui permet de purifier physiquement et psychologiquement l’organisme et le mental.",
  "bellaVitaPackQ4": "Quel rôle joue le Alca Vita ?",
  "bellaVitaPackQ4R1": "Alca Vita est une forme alcaline du vinaigre qui permet de rendre un équilibre acido-basique du corps.",
  "bellaVitaPackQ4R2": "Alca Vita est le complément alimentaire parfait en matière de lutte contre l'acidose et de rétablissement de l'équilibre acido-basique.",
  "bellaVitaPackQ4R3": "Alca Vita est le complément par excellence en matière de lutte contre l’alcalanisation et le rétablissement des muscles aprés l’effort.",
  "bellaVitaPackQ5": "Quels sont les plus d’Nutri-logics concernant les compléments ?",
  "bellaVitaPackQ5R1": "Pas de différence spécifique par rapport à la concurrence",
  "bellaVitaPackQ5R2": "90% de la production est vegan, sans excipient, sans conservateur, sans lactose et gluten",
  "bellaVitaPackQ5R3": "Ils ont la particularité d’être mieux emballés",

  "sportPack": "Le pack sport",

  "sportPackQ1": "De quoi est composé le pack sport ?",
  "sportPackQ1R1": "L-Tyro Vita, Alca Vita, B-Alanine, Spiruline Vita",
  "sportPackQ1R2": "L-Tyro Vita, Alca Vita, B-Alanine, Ashwaganda",
  "sportPackQ1R3": "MG Top, Alca Vita, B-Alanine, Spirulina Vita",
  "sportPackQ2": "A partir de combien de pas par jour n’est-on plus considéré comme sédentaire ?",
  "sportPackQ2R1": "3500",
  "sportPackQ2R2": "7000",
  "sportPackQ2R3": "10000",
  "sportPackQ3": "Quel rôle joue la L-Tyro Vita  ?",
  "sportPackQ3R1": "L-Tyro Vita améliore significativement la mémoire, la concentration et la vigilance tout en réduisant l’état de fatigue",
  "sportPackQ3R2": "L-Tyro Vita régule la température du corps et aide le cerveau dans la gestion des neurotransmetteurs",
  "sportPackQ3R3": "L-Tyro Vita améliore les performances physiques",
  "sportPackQ4": "Quel rôle joue l’Alca Vita ?",
  "sportPackQ4R1": "Alca Vita est le complément alimentaire parfait en matière de lutte contre l'acidose et de rétablissement de l'équilibre acido-basique",
  "sportPackQ4R2": "Alca Vial est le complément alimentaire par excellence pour augmenter l’acidité nécessaire à l’élimination",
  "sportPackQ4R3": "Alca Vita est la forme d’absorption la plus appropriée pour les muscles dans le rééquilibrage acido-basique",
  "sportPackQ5": "Quel rôle joue la B-Alanine ?",
  "sportPackQ5R1": "B-Alanine acide aminé, dans le foie, les cellules musculaires absorbent la bêta-alanine et la combinent avec de l’histidine pour produire la carnosine d’où son nom scientifique, β-alanyl-L-histidine. C’est de cette synthèse que découlent les multiples bienfaits de la bêta-alanine",
  "sportPackQ5R2": "B-Alanine , au même titre que le bicarbonate de soude, réduit l’acidité du corps",
  "sportPackQ5R3": "B-Alanine, joue un rôle très important dans la détoxification, elle permet au foie de synthétiser L’Analyle phosphorique",
  "sportPackQ6": "Quel rôle joue la Spiruline Vita ?",
  "sportPackQ6R1": "La spiruline est une microalgue ou plancton qui pousse sur la surface alcaline des lacs. Elle présente une concentration en protéines (70 %). Elle est riche en bêta-carotène, fer biodisponible, acide gras essentiels, vitamines du groupe B (dont une grande concentration en vitamine B12), vitamine E, calcium, zinc, sélénium, phosphore, magnésium et chlorophylle",
  "sportPackQ6R2": "La Spiruline joue un rôle fondamentale dans l’élimination de l’acide lactique produit par le corps",
  "sportPackQ6R3": "La Spirulina est une microalgue qui a pour effet de jouer le rôle d’un anti-douleur naturel pour le corps. Sa composition riche en Vit B, permet une régénération musculaire et une cicatrisation rapide",

  "weightManagementPack": "Le pack gestion du poids",

  "weightManagementPackQ1": "De quoi est composée le pack  ?",
  "weightManagementPackQ1R1": "Oméga Calanus & Verda-T-Vita",
  "weightManagementPackQ1R2": "Epura Vita &  Detox Vita",
  "weightManagementPackQ1R3": "Renal Vita & Save Epath",
  "weightManagementPackQ2": "Quels sont les facteurs de prise de poids ?",
  "weightManagementPackQ2R1": "La non-assimilation du sucre par la cellule",
  "weightManagementPackQ2R2": "L’incapacité à la cellule d’éliminer les glucides",
  "weightManagementPackQ2R3": " Multi factoriels",
  "weightManagementPackQ3": "Quel rôle joue l’Oméga Calanus  ?",
  "weightManagementPackQ3R1": "L’Oméga Calanus est un extrait naturel de lipides de Calanus finmarchicus, un copépode herbivore communément trouvé dans l’océan Atlantique Nord. Ils sont sujets à une digestion plus lente, ce qui permet aux lipides d’atteindre une zone plus éloignée de l’intestin",
  "weightManagementPackQ3R2": "L’Oméga Calanus est un extrait naturel de lipides de Calanus finmarchicus, un copépode herbivore communément trouvé dans l’océan Pacifique Sud. Ils sont sujets à une digestion plus lente, ce qui permet aux lipides d’atteindre une zone plus éloignée de l’intestin",
  "weightManagementPackQ3R3": "L’Oméga Calanus est un extrait naturel de lipides de Calanus finmarchicus, un copépode Carnivore communément trouvé dans l’océan Atlantique Sud. Ils sont sujets à une digestion plus rapide, ce qui permet aux glucides d’atteindre une zone plus éloignée de l’intestin",
  "weightManagementPackQ4": "Quel rôle joue le Verda-T-Vita ?",
  "weightManagementPackQ4R1": "Verda T vita, sa richesse en principes actifs fait du thé vert un des plus puissants antioxydants qui stimule l’action brûle graisse et participe activement à la détoxification hépatique !",
  "weightManagementPackQ4R2": "Verda T vita, sa richesse en principes actifs fait du thé vert un des plus puissants anticoagulants qui stimule l’action brûle graisse et participe activement à la détoxification hépatique !",
  "weightManagementPackQ4R3": "Verda T vita, sa richesse en principes inactifs fait du thé vert un des plus puissants vecteurs qui stimule l’action brûle graisse et participe activement à l’élimination rénale !",
  "weightManagementPackQ5": "La particularité des contenants Nutri-logics sont ?",
  "weightManagementPackQ5R1": "100% Biotechnologique",
  "weightManagementPackQ5R2": "100% Écologique",
  "weightManagementPackQ5R3": "100% Biodégradable",

  "businessPack": "Le pack business",

  "businessPackQ1": "De quoi est composée le pack  ?",
  "businessPackQ1R1": "L-Tyro Vita, MG Top, Mind Vita",
  "businessPackQ1R2": "MG Top, Omega Boost Vita, Vitamine D",
  "businessPackQ1R3": "Mind Vita, MG Malate, Zinc",
  "businessPackQ2": "Le pack business s’adresse plus à ?",
  "businessPackQ2R1": "L’employé de bureau",
  "businessPackQ2R2": "Le dirigeant d’entreprise",
  "businessPackQ2R3": "Le chômeur",
  "businessPackQ3": "Quel rôle joue le L-Tyro vita ?",
  "businessPackQ3R1": "L-Tyro Vita améliore significativement la mémoire, la concentration et la vigilance tout en réduisant l’état de fatigue",
  "businessPackQ3R2": "L-Tyro Vita régule la température du corps et aide le cerveau dans la gestion des neurotransmetteurs",
  "businessPackQ3R3": "L-Tyro Vita améliore les performance physiques",
  "businessPackQ4": "Quel rôle joue le MG Top ?",
  "businessPackQ4R1": "Le MG Top va stimuler votre cerveau et fournir la 1ère énergie nécessaire au corps. Il va vous booster le matin, créer l’énergie nécessaire à un bon démarrage",
  "businessPackQ4R2": "Le MG top dopant naturel, il stimule les neurotransmetteur par induction électromagnétique",
  "businessPackQ4R3": "Le MG Top est un complément qui offre un tranquillisant et relaxant naturel",
  "businessPackQ5": "Quel rôle joue le Mind Vita ?",
  "businessPackQ5R1": "Le Mind vita va renforcer votre  concentration et motivation (la Rhodiola) anti-stress naturel",
  "businessPackQ5R2": "Le Mind vita va renforcer votre  confiance et système immunitaire (la Rhodiola) anti-biotique naturel",
  "businessPackQ5R3": "Le Mind vita va renforcer votre  self contrôle et vos neurotransmetteurs (la Rhadiola) anti-onde négative naturelle",
  "businessPackQ6": "Le marché mondial du complément alimentaire représentait en 2020 ?",
  "businessPackQ6R1": "12 600 000 000 €",
  "businessPackQ6R2": "250 000 000 000 €",
  "businessPackQ6R3": "130 000 000 000 €",

  "wellnessPack": "Le pack wellness",

  "wellnessPackQ1": "De quoi est composée le pack  ?",
  "wellnessPackQ1R1": "L-Tyro Vita 125, MG Top, Epura Vita",
  "wellnessPackQ1R2": "L-Tyro Vita, MG Top, Epura Vita",
  "wellnessPackQ1R3": "L-Tyro, Ashwaganda, Cassis Vita",
  "wellnessPackQ2": "A qui s’adresse le pack Wellness ?",
  "wellnessPackQ2R1": "Les personnes qui vont bien et qui veulent maintenir cet état",
  "wellnessPackQ2R2": "Les personnes qui vont mal et qui veulent aller mieux",
  "wellnessPackQ2R3": "Les personnes en burn-out",
  "wellnessPackQ3": "Quel rôle joue le  L-Tyro Vita 125 ?",
  "wellnessPackQ3R1": "L-Tyro vita 125 Idéal pour apaiser la fatigue mentale, promouvoir la concentration et la mémoire et améliorer le sentiment de bien-être !",
  "wellnessPackQ3R2": "L-Tyro vita 125 Idéal pour apaiser la fatigue physique, promouvoir la concentration et la mémoire et améliorer le sentiment de bien-être !",
  "wellnessPackQ3R3": "L-Tyro vita 125 Idéal pour apaiser la fatigue émotionnelle, promouvoir la concentration et la mémoire et améliorer le sentiment de bien-être !",
  "wellnessPackQ4": "Quel rôle joue le MG Top ?",
  "wellnessPackQ4R1": "Le MG Top va stimuler votre cerveau et fournir la 1ère énergie nécessaire au corps. Il va vous booster le matin, créer l’énergie nécessaire à un bon démarrage",
  "wellnessPackQ4R2": "Le MG top dopant naturel, il stimule les neurotransmetteur par induction électromagnétique",
  "wellnessPackQ4R3": "Le MG Top est un complément qui offre un tranquillisant et relaxant naturel",
  "wellnessPackQ5": "Quel rôle joue l’Epura Vita ?",
  "wellnessPackQ5R1": "Epura Vita est une formulation à base de 5 plantes et super aliments extrêmement importants pour le processus de la détoxification et pour le bon fonctionnement des organes d’élimination",
  "wellnessPackQ5R2": "Epura Vita est une formulation à base de 5 plantes et super aliments extrêmement importants pour le processus du renouvellement des tissus",
  "wellnessPackQ5R3": "Epura Vita est une formulation à base de 5 plantes et super aliments extrêmement importants pour le processus de gestion du  cortisol",
  "wellnessPackQ6": "L’estimation du marché mondial du complément alimentaire en 2024 est estimé à ?",
  "wellnessPackQ6R1": "200 000 000 000 €",
  "wellnessPackQ6R2": "225 000 000 000 €",
  "wellnessPackQ6R3": "250 000 000 000 €",

  "detoxPack": "Le pack détox",

  "detoxPackQ1": "De quoi est composée le pack  ?",
  "detoxPackQ1R1": "NAC Vita, Epura Vita, Broco Vita",
  "detoxPackQ1R2": "Epura Vita, Renal Vita, Save Hepat",
  "detoxPackQ1R3": "NAC Vita, Epura Vita, Detox Vita",
  "detoxPackQ2": "Le foie détoxifie et les reins éliminent ?",
  "detoxPackQ2R1": "Vrai",
  "detoxPackQ2R2": "Faux",
  "detoxPackQ3": "Quel rôle joue le Nac Vita ?",
  "detoxPackQ3R1": "La N-acétyl-L-cystéine (NAC) est une forme acétylée d’un acide aminé – la cystéine. Elle s'est avérée très efficace en tant qu’antioxydant, détoxifiant, antimicrobien, mucolytique et anti-inflammatoire",
  "detoxPackQ3R2": "La N-acétyl-L-cystéine (NAC) est une molécule protéinique qui provient de la cystéine phosphorique. Elle est d’une efficacité incroyable dans la détoxification et la fluidité des corps étrangers",
  "detoxPackQ3R3": "Le NAC est une particule naturelle qui provient tout droit des laboratoires NESTLé mieux connus sous la forme de NIC-NAC",
  "detoxPackQ4": "Quel rôle joue L’Epura Vita ?",
  "detoxPackQ4R1": "Epura Vita est une formulation à base de 5 plantes et super aliments extrêmement importants pour le processus de la détoxification et pour le bon fonctionnement des organes d’élimination",
  "detoxPackQ4R2": "Epura Vita est une formulation à base de 5 plantes et super aliments extrêmement importants pour le processus du renouvellement des tissus",
  "detoxPackQ4R3": "Epura Vita est une formulation à base de 5 plantes et super aliments extrêmement importants pour le processus de gestion du cortisol",
  "detoxPackQ5": "Quel rôle joue le Broco Vita ?",
  "detoxPackQ5R1": "Les propriétés bénéfiques du brocoli comprennent un effet positif sur la digestion, les systèmes cardiovasculaire et immunitaire, ainsi que des effets anti-inflammatoires et anti-cancérigènes",
  "detoxPackQ5R2": "Les propriétés bénéfiques du brocoli comprennent un effet boostant sur l’intestin, le système digestif",
  "detoxPackQ5R3": "Les propriétés bénéfiques du brocoli comprennent un effet anti-inflammatoire et antidiurétique",
  "detoxPackQ6": "Combien de pomme faudrait-il manger de nos jours pour avoir la même valeur nutritive en vitamine C qu’une pomme de 1950 ?",
  "detoxPackQ6R1": "20",
  "detoxPackQ6R2": "50",
  "detoxPackQ6R3": "100",

  "sleepManagementPack": "Le pack gestion du sommeil",

  "sleepManagementPackQ1": "De quoi est composée le pack  ?",
  "sleepManagementPackQ1R1": "Aloé Vita & Cardioméga 700+",
  "sleepManagementPackQ1R2": "Berbé Vita & CoQ10",
  "sleepManagementPackQ1R3": "Mind Vita & Noctivance",
  "sleepManagementPackQ2": "Le pourcentage de la population ayant une problématique de sommeil se rapproche de ?",
  "sleepManagementPackQ2R1": "15%",
  "sleepManagementPackQ2R2": "55%",
  "sleepManagementPackQ2R3": "73,5%",
  "sleepManagementPackQ3": "Quel rôle joue le Mind Vita ?",
  "sleepManagementPackQ3R1": "Mind Vita est un complément alimentaire phytothérapeutique agençant : Les effets tonifiants et adaptogènes de la Rhodiola. Les vertus antistress de la Griffonia. L'action antidépressive du safran.",
  "sleepManagementPackQ3R2": "Mind Vita permet au bol alimentaire d’avoir un PH intestinal parfait",
  "sleepManagementPackQ3R3": "Mind Vita participe à une évacuation rapide des selles",
  "sleepManagementPackQ4": "Quel rôle joue le  Noctivance ?",
  "sleepManagementPackQ4R1": "Mélatonine et mélisse en spray buccal. Contribuent à réduire le temps d'endormissement, à réduire la fatigue. Contribuent à atténuer les effets du décalage horaire",
  "sleepManagementPackQ4R2": "Mélatonine et mélisse en spray buccal. Contribuent à réduire le niveau de stress, élimine les problèmes antispasmodiques",
  "sleepManagementPackQ4R3": "Mélatonine et mélisse en spray buccal. Contribuent à atteindre une zone de sommeil profond, permet une production d’onde Alpha, propice à la relaxation",
  "sleepManagementPackQ5": "Un des constats que l’on peut tirer des observations de l’OMS concernant le nombre de décès suite à la «mal bouffe» ?",
  "sleepManagementPackQ5R1": "Trop de viande, trop de plastique, trop de diversité alimentaire",
  "sleepManagementPackQ5R2": "Trop de sucre, trop de pesticide, trop de mal bouffe",
  "sleepManagementPackQ5R3": "Trop de stress, trop de pollution, trop de colorant",

  "stressManagementPack": "Le pack Relax",

  "stressManagementPackQ1": "De quoi est composée le pack  ?",
  "stressManagementPackQ1R1": "L-Tyro Vita, Ashwaganda, Mind Vita",
  "stressManagementPackQ1R2": "MG Top, Skin Vita, Curcu Vita",
  "stressManagementPackQ1R3": "Curlong’arti Vita, MG Top, Artério Vita",
  "stressManagementPackQ2": "Les surrénales jouent-elles un rôle dans le processus du stress  ?",
  "stressManagementPackQ2R1": "Oui",
  "stressManagementPackQ2R2": "Non",
  "stressManagementPackQ3": "Quel rôle joue le L-Tyro vita  ?",
  "stressManagementPackQ3R1": "L-Tyro Vita améliore significativement la mémoire, la concentration et la vigilance tout en réduisant l’état de fatigue",
  "stressManagementPackQ3R2": "L-Tyro Vita régule la température du corps et aide le cerveau dans la gestion des neurotransmetteurs",
  "stressManagementPackQ3R3": "L-Tyro Vita améliore les performance physiques",
  "stressManagementPackQ4": "Quel rôle joue le l’Ashwaganda ?",
  "stressManagementPackQ4R1": "Ashwaganda réduit le stress et l’anxiété, augmente les niveaux de cortisol, améliore la mémoire et les performances cognitives, diminue les poussées de sucre",
  "stressManagementPackQ4R2": "Ashwaganda calme l’hypophyse, glande déclencheuse du mode fuite ou combat",
  "stressManagementPackQ4R3": "Ashwaganda régule le niveau de cortisol produit dans les reins et stimule l’hypophyse, ainsi, elle donne l’ordre aux neurotransmetteurs de mettre le corps en alerte",
  "stressManagementPackQ5": "Quel rôle joue le Mind Vita ?",
  "stressManagementPackQ5R1": "Le Mind vita va renforcer votre  concentration et motivation (la Rhodiola) anti-stress naturel",
  "stressManagementPackQ5R2": "Le Mind vita va renforcer votre  confiance et système immunitaire (la Rhodiola) antibiotique naturel",
  "stressManagementPackQ5R3": "Le Mind vita va renforcer votre  self contrôle et vos neurotransmetteurs (la Rhadiola) anti-onde négative naturelle",
  "stressManagementPackQ6": "Si on compare l’homme d’autrefois et l’homme d’aujourd’hui sur le plan activité physique et nutrition, que peut-on dire ?",
  "stressManagementPackQ6R1": "Qu’il dépensait plus que ce qu’il consommait en calorie ingérées",
  "stressManagementPackQ6R2": "Qu’il dépensait autant de calorie que ce qu’il en consommait",
  "stressManagementPackQ6R3": "Qu’il dépensait moins de calorie que ce qu’il en consommait",

  "beautyPack": "Le pack beauté",

  "beautyPackQ1": "De quoi est composée le pack ?",
  "beautyPackQ1R1": "Skin Vita & Phénol Vita",
  "beautyPackQ1R2": "Curcu Vita & D3 Vita",
  "beautyPackQ1R3": "Cérébroméga Boost & Aloè Vita",
  "beautyPackQ2": "Le jeûne nocturne équivaut minimum à au moins ?",
  "beautyPackQ2R1": "8h",
  "beautyPackQ2R2": "14h",
  "beautyPackQ2R3": "18h",
  "beautyPackQ3": "Quel rôle joue le Skin Vita ?",
  "beautyPackQ3R1": "Le SKIN Vita Effet anti-rides significatif avec peau lisse au bout de 6 semaines et plus soutenu après 12 semaines de supplémentation",
  "beautyPackQ3R2": "Le SKIN Vita Effet anti-rides significatif avec peau lisse au bout de 6 Jours et plus soutenu après 12 jours de supplémentation",
  "beautyPackQ3R3": "Le SKIN Vita Effet anti-rides significatif avec peau lisse au bout de 6 heures et plus soutenu après 12 heures de supplémentation",
  "beautyPackQ4": "Quel rôle joue le Phénol vita ?",
  "beautyPackQ4R1": "Les Polyphénols sont des antioxydants extrêmement efficaces qui peuvent aider à prévenir les maladies graves et à protéger la santé humaine. Phénol Vita un vrai prodige pour toute la  protection du corps",
  "beautyPackQ4R2": "Les Polyphénols sont des anti-inflammatoires extrêmement efficaces qui peuvent aider à prévenir les maladies graves et à protéger la santé humaine. Phénol Vita un vrai prodige pour toute la protection du corps",
  "beautyPackQ4R3": "Les Polyphénols sont des hormones extrêmement efficaces qui peuvent aider à prévenir les maladies graves et à protéger la santé humaine. Phénol Vita un vrai prodige pour toute la  protection du corps",
  "beautyPackQ5": "Sur une journée, que doit-on favoriser, sur le plan physiologique et chrono-alimentaire ?",
  "beautyPackQ5R1": "Le matin, la dopamine et nourrir le cerveau (protéine), à midi, la dopamine ( protéine), l’après-midi, stimuler le cerveau (sucre rapide), le soir favoriser l’état de relaxation(glucide lent)",
  "beautyPackQ5R2": "Le matin, créer un pic d’insuline pour stimuler, à midi, idem, à 16h, du sucre raffiné, le soir des protéines",
  "beautyPackQ5R3": "Le matin, des sucres rapides, à midi, des sucres rapides, à 16h, des sucres rapides, le soir des sucres rapides et des protéines",

  "certificationIntro": "Introduction de la certification",

  "cellularPhysiology": "La physiologie cellulaire",

  "cellularPhysiologyQ1": "Quels sont les composants qui définissent la cellule ?",
  "cellularPhysiologyQ1R1": "Le noyau",
  "cellularPhysiologyQ1R2": "Le facteur intrinsèque",
  "cellularPhysiologyQ1R3": "Les mitochondries",
  "cellularPhysiologyQ1R4": "La membrane",
  "cellularPhysiologyQ2": "La fonction de la mitochondrie est ?",
  "cellularPhysiologyQ2R1": "La production de protéines",
  "cellularPhysiologyQ2R2": "La survie de la cellule",
  "cellularPhysiologyQ2R3": "La production d'énergie",
  "cellularPhysiologyQ3": "Quels sont les éléments nécessaires pour produire de l'énergie dans une cellule ?",
  "cellularPhysiologyQ3R1": "Oxygène",
  "cellularPhysiologyQ3R2": "Substrat énergétique",
  "cellularPhysiologyQ3R3": "Glycogène",
  "cellularPhysiologyQ3R4": "Glucose",
  "cellularPhysiologyQ4": "Quelle est la particularité de la membrane cellulaire ?",
  "cellularPhysiologyQ4R1": "Définit la cellule",
  "cellularPhysiologyQ4R2": "Porosité cellulaire",
  "cellularPhysiologyQ4R3": "Transfert des nutriments et élimination des déchets",
  "cellularPhysiologyQ4R4": "Contient des phospholipides",
  "cellularPhysiologyQ4R5": "Permet la protection de l'ADN",
  "cellularPhysiologyQ5": "Qu'est-ce que l'ADN ?",
  "cellularPhysiologyQ5R1": "Producteur de protéines",
  "cellularPhysiologyQ5R2": "Producteur d'énergie",
  "cellularPhysiologyQ5R3": "Producteur d'ARN",
  "cellularPhysiologyQ5R4": "Centre de commande de la cellule",
  "cellularPhysiologyQ5R5": "Code génétique",

  "immunity": "L’immunité",

  "immunityQ1": "Qu’est-ce que le système immunitaire ?",
  "immunityQ1R1": "Système de défense du corps",
  "immunityQ1R2": "Système de protection du cerveau",
  "immunityQ1R3": "Système de défense des cellules (barrière naturelle, peau, microbiote)",
  "immunityQ2": "De quoi est composé le système immunitaire ?",
  "immunityQ2R1": "De cellules immunitaires",
  "immunityQ2R2": "De globules blancs",
  "immunityQ2R3": "De lymphocytes",
  "immunityQ2R4": "De Ptériocytes",
  "immunityQ2R5": "De macrophages",
  "immunityQ2R6": "D'antigènes",
  "immunityQ3": "Quelles sont les fonctions des globules blancs ?",
  "immunityQ3R1": "Produire de l'énergie",
  "immunityQ3R2": "Transporter l'oxygène",
  "immunityQ3R3": "Coagulation du sang",
  "immunityQ3R4": "Défense de l'organisme",
  "immunityQ4": "Quelle est l’importance de l’inflammation sur le plan physiologique ?",
  "immunityQ4R1": "Détruit les tissus",
  "immunityQ4R2": "Consomme de l'énergie",
  "immunityQ4R3": "Protège contre l'infection",
  "immunityQ4R4": "Protège contre le cancer",
  "immunityQ4R5": "Régénère les tissus",

  "fertility": "La fertilité",

  "fertilityQ1": "Quelle est la différence entre la fertilité de l’homme et celle de la femme ?",
  "fertilityQ1R1": "La fertilité de la femme s'appui sur les seins alors que celle de l'homme s'appui sur les testicules",
  "fertilityQ1R2": "La fertilité de femme nécessite la progestérone alors que celle de l'homme nécessite l'œstrogène",
  "fertilityQ1R3": "La femme utilise les ovaires pour produire l'oestrogène alors que l'homme utilise les testicules pour produire la testostérone",
  "fertilityQ2": "Quels sont les facteurs qui peuvent perturber la fertilité ?",
  "fertilityQ2R1": "Les métaux lourds",
  "fertilityQ2R2": "Les carences",
  "fertilityQ2R3": "Les perturbateurs endocriniens (ex: pesticides)",
  "fertilityQ2R4": "Le style de vie sédentaire",
  "fertilityQ2R5": "Les hormones",
  "fertilityQ3": "Quel est le rôle de la prostate dans la fertilité ?",
  "fertilityQ3R1": "Produire les spermatozoides",
  "fertilityQ3R2": "Produire le liquide prostatique",
  "fertilityQ3R3": "Produire le sperme",
  "fertilityQ4": "Quel est le rôle de l’équilibre hormonal dans la fertilité de la femme ?",
  "fertilityQ4R1": "Permet une meilleure fertilité",
  "fertilityQ4R2": "Permet une meilleure santé",
  "fertilityQ4R3": "Permet une meilleure production de lait maternel",
  "fertilityQ4R4": "Permet de lutter contre les infection génitales",

  "digestionIntestineMicrobiota": "La digestion, l'intestin, le microbiote",

  "digestionIntestineMicrobiotaQ1": "Qu'entend-on par la digestion ?",
  "digestionIntestineMicrobiotaQ1R1": "Processus de dégradation des acides aminés, des acides gras et du glucose",
  "digestionIntestineMicrobiotaQ1R2": "Processus de production de l'énergie",
  "digestionIntestineMicrobiotaQ1R3": "Processus d'absorption des aliments",
  "digestionIntestineMicrobiotaQ1R4": "Processus de dégradation des aliments en composants absorbables",
  "digestionIntestineMicrobiotaQ2": "Quels sont les spécificités de la digestion des macronutriments ?",
  "digestionIntestineMicrobiotaQ2R1": "Glucose, lipides, protéines",
  "digestionIntestineMicrobiotaQ2R2": "Acides gras, acides aminés, sucres simples",
  "digestionIntestineMicrobiotaQ2R3": "Acide gras, protéines, glucose",
  "digestionIntestineMicrobiotaQ3": "Qu’est-ce que le microbiote ?",
  "digestionIntestineMicrobiotaQ3R1": "Organe digestif",
  "digestionIntestineMicrobiotaQ3R2": "Organe cellulaire",
  "digestionIntestineMicrobiotaQ3R3": "Ensemble des micro-organismes dans l'intestin",
  "digestionIntestineMicrobiotaQ3R4": "Ensemble des bactéries dans le corps",
  "digestionIntestineMicrobiotaQ4": "Quel est le rôle des fibres alimentaires ?",
  "digestionIntestineMicrobiotaQ4R1": "Améliorer le transit intestinal",
  "digestionIntestineMicrobiotaQ4R2": "Améliorer la glycémie",
  "digestionIntestineMicrobiotaQ4R3": "Nourrir le microbiote",
  "digestionIntestineMicrobiotaQ4R4": "Empêcher la fermentation des aliments",
  "digestionIntestineMicrobiotaQ4R5": "Favoriser la digestion",
  "digestionIntestineMicrobiotaQ5": "Quel est le rôle de la barrière intestinale dans l’absorption ?",
  "digestionIntestineMicrobiotaQ5R1": "Finaliser la digestion",
  "digestionIntestineMicrobiotaQ5R2": "Absorber et transporter les micronutriments",
  "digestionIntestineMicrobiotaQ5R3": "Lutter contre l'inflammation",
  "digestionIntestineMicrobiotaQ5R4": "Système de défense",
  "digestionIntestineMicrobiotaQ5R5": "Empêcher le passage des acides gras",

  "liverAndKidneys": "Le foie et les reins",

  "liverAndKidneysQ1": "Qu’est-ce que le processus d'élimination ?",
  "liverAndKidneysQ1R1": "Débarasser le corps des déchets",
  "liverAndKidneysQ1R2": "Débarrasser le corps des selles",
  "liverAndKidneysQ1R3": "Débarrasser le corps de l'excès de proteines",
  "liverAndKidneysQ2": "Quel le rôle du foie dans la digestion ?",
  "liverAndKidneysQ2R1": "Le stockage des nutriments",
  "liverAndKidneysQ2R2": "La production d'hormones",
  "liverAndKidneysQ2R3": "La régulation de l'appétit",
  "liverAndKidneysQ2R4": "La sécrétion des selles biliaires",
  "liverAndKidneysQ3": "Quel autre rôle, le foie, peut-il jouer ?",
  "liverAndKidneysQ3R1": "Le stockage",
  "liverAndKidneysQ3R2": "Le tri et le nettoyage des nutriments",
  "liverAndKidneysQ3R3": "La protection des tissus",
  "liverAndKidneysQ3R4": "La production d'énergie",
  "liverAndKidneysQ3R5": "La détoxification",
  "liverAndKidneysQ4": "Quel est le rôle des reins dans l'élimination ?",
  "liverAndKidneysQ4R1": "Spécialisé dans l'élimination des toxines liposolubles",
  "liverAndKidneysQ4R2": "Spécialisé dans l'élimination des toxines hydrosolubles",
  "liverAndKidneysQ4R3": "La détoxification",
  "liverAndKidneysQ5": "Quel autre rôle, les reins, peuvent il avoir ?",
  "liverAndKidneysQ5R1": "La régulation de la quantité d'eau dans l'organisme",
  "liverAndKidneysQ5R2": "La régulation d'électrolytes ou des ions'",
  "liverAndKidneysQ5R3": "La régulation du taux de calcium",
  "liverAndKidneysQ5R4": "La détoxification",

  "beautySkinAntiAging": "La beauté, la peau, l’anti-âge",

  "beautySkinAntiAgingQ1": "Quelles sont les fonctions physiologiques de la peau ?",
  "beautySkinAntiAgingQ1R1": "Protection de l'organisme",
  "beautySkinAntiAgingQ1R2": "Synthèse du cholestérol",
  "beautySkinAntiAgingQ1R3": "Synthèse de la vitamine D",
  "beautySkinAntiAgingQ1R4": "Production de vitamine E",
  "beautySkinAntiAgingQ2": "Quels sont les tissus qui constituent la peau ?",
  "beautySkinAntiAgingQ2R1": "Épiderme",
  "beautySkinAntiAgingQ2R2": "Derme",
  "beautySkinAntiAgingQ2R3": "Tissus dermatologiques",
  "beautySkinAntiAgingQ2R4": "Hypoderme",
  "beautySkinAntiAgingQ3": "Dans quel cas de figure le processus de cicatrisation intervient-il dans la peau ?",
  "beautySkinAntiAgingQ3R1": "Obésité",
  "beautySkinAntiAgingQ3R2": "Maladie autoimmune",
  "beautySkinAntiAgingQ3R3": "Traumatisme (plaies, blessures)",
  "beautySkinAntiAgingQ3R4": "Brûlure",
  "beautySkinAntiAgingQ3R5": "Vieillissement de la peau",
  "beautySkinAntiAgingQ4": "Comment ralentir le vieillissement ?",
  "beautySkinAntiAgingQ4R1": "Avec des antioxydants",
  "beautySkinAntiAgingQ4R2": "Avec des polyphénols",
  "beautySkinAntiAgingQ4R3": "Avec des écrans solaires",
  "beautySkinAntiAgingQ4R4": "En detoxifiant la peau",

  "nervousSystemSleepStress": "Le système nerveux, le sommeil, le stress",

  "nervousSystemSleepStressQ1": "De quoi est composé le système nerveux ?",
  "nervousSystemSleepStressQ1R1": "Système nerveux central et périphérique",
  "nervousSystemSleepStressQ1R2": "Cerveau, nerfs et bulbe",
  "nervousSystemSleepStressQ1R3": "Influx nerveux, nerfs et moelle épinière",
  "nervousSystemSleepStressQ2": "Quelles sont les fonctions du système nerveux ?",
  "nervousSystemSleepStressQ2R1": "Production d'influx nerveux",
  "nervousSystemSleepStressQ2R2": "Contraction musculaire uniquement",
  "nervousSystemSleepStressQ2R3": "Commander tous les organes",
  "nervousSystemSleepStressQ2R4": "Gère les cycles circadiens",
  "nervousSystemSleepStressQ3": "Quel est le rôle des neurotransmetteurs ?",
  "nervousSystemSleepStressQ3R1": "Transmettre les molécules neuronales",
  "nervousSystemSleepStressQ3R2": "Fait le relai entre les nerfs et les autres organes ou cellules",
  "nervousSystemSleepStressQ3R3": "Protéger les synapses",
  "nervousSystemSleepStressQ3R4": "Produire les synapes",
  "nervousSystemSleepStressQ4": "Comment optimiser le sommeil ?",
  "nervousSystemSleepStressQ4R1": "En optimisant les neurotransmetteurs du système sympathique",
  "nervousSystemSleepStressQ4R2": "En optimisant la sérotonine par le tryptophane",
  "nervousSystemSleepStressQ4R3": "En optimisant le taux de mélatonine par le 5HTP et la vitamine B6",
  "nervousSystemSleepStressQ5": "Comment mieux gérer le stress ?",
  "nervousSystemSleepStressQ5R1": "En optimisant les neurotransmetteurs du système parasympathique",
  "nervousSystemSleepStressQ5R2": "En optimisant la sérotonine par la dopamine",
  "nervousSystemSleepStressQ5R3": "En optimisant le taux de mélatonine par la tyrosine et la vitamine B6 et C",
  "nervousSystemSleepStressQ6": "Comment protéger le système nerveux et le cerveau contre le vieillissement et la toxicité ?",
  "nervousSystemSleepStressQ6R1": "Par des antiinflammatoires comme les oméga 3 ou les polyphénols",
  "nervousSystemSleepStressQ6R2": "Par des phospholipides comme la phosphatidylcholine (vitamine B12)",
  "nervousSystemSleepStressQ6R3": "Par le magnésium",
  "nervousSystemSleepStressQ6R4": "Par les antidépresseurs",

  "cardiovascularSystem": "Le système cardiovasculaire",

  "cardiovascularSystemQ1": "De quoi est composé le système cardiovasculaire ?",
  "cardiovascularSystemQ1R1": "Le cœur et les vaisseaux sanguins",
  "cardiovascularSystemQ1R2": "Les veines et les artères",
  "cardiovascularSystemQ1R3": "Le cœur et le sang",
  "cardiovascularSystemQ2": "Comment diminuer le risque cardiovasculaire  ?",
  "cardiovascularSystemQ2R1": "En stimulant la circulation sanguine",
  "cardiovascularSystemQ2R2": "Manger des aliments à indice glycémique faible",
  "cardiovascularSystemQ2R3": "En optimisant la fixation du calcium dans l'os par la vitamine D, K2 et magnésium",
  "cardiovascularSystemQ2R4": "Avec des oméga 3",
  "cardiovascularSystemQ3": "Comment améliorer la fonction cardiaque ?",
  "cardiovascularSystemQ3R1": "Avec du coenzyme Q10 et du magnésium",
  "cardiovascularSystemQ3R2": "Avec de la vitamine B2 et B3",
  "cardiovascularSystemQ3R3": "Avec des acides gras trans",
  "cardiovascularSystemQ4": "Quels sont les rôles du réseau lymphatique ?",
  "cardiovascularSystemQ4R1": "Transport de l'oxygène",
  "cardiovascularSystemQ4R2": "Transport des minéraux, des lipides et oligo-éléments",
  "cardiovascularSystemQ4R3": "Transport des protéines",
  "cardiovascularSystemQ4R4": "Transport des céllules du système immunitaire",
  "cardiovascularSystemQ5": "Comment améliorer la circulation sanguine et lymphatique ?",
  "cardiovascularSystemQ5R1": "Avec des plantes qui améliorent l'élasticité des vaisseaux comme ginseng",
  "cardiovascularSystemQ5R2": "Avec des aliments vasodilatateurs",
  "cardiovascularSystemQ5R3": "Avec des antioxydants",
  "cardiovascularSystemQ5R4": "Avec des vitamines B",

  "bonesAndCartilage": "Les os et le cartilage",

  "bonesAndCartilageQ1": "De quoi se compose l’os ?",
  "bonesAndCartilageQ1R1": "De masse solide composée exclusivement de calcium",
  "bonesAndCartilageQ1R2": "De cellules ostéoblastes",
  "bonesAndCartilageQ1R3": "De cellules osteoclastes",
  "bonesAndCartilageQ1R4": "De cellules ostéogènes",
  "bonesAndCartilageQ1R5": "De réserves minérales (calcium, phosphore, magnésium, silicium)",
  "bonesAndCartilageQ2": "Quelles sont les fonctions du tissu osseux ?",
  "bonesAndCartilageQ2R1": "Constituer une reserve minérale pour temporiser l'acidité",
  "bonesAndCartilageQ2R2": "Locomotion",
  "bonesAndCartilageQ2R3": "Participer à l'hématopoïèse",
  "bonesAndCartilageQ2R4": "Produire les plaquettes immunitaires",
  "bonesAndCartilageQ2R5": "Protéger les organes",
  "bonesAndCartilageQ2R6": "Reserve minerale",
  "bonesAndCartilageQ3": "Quelle est la différence entre l’os et le cartilage ?",
  "bonesAndCartilageQ3R1": "Le cartilage est constitué de chondrocytes et l'os d'osteocytes",
  "bonesAndCartilageQ3R2": "Le cartilage se trouve uniquement dans les articulations",
  "bonesAndCartilageQ3R3": "L'os est moins rigide que le cartilage",
  "bonesAndCartilageQ4": "Comment améliorer la santé de l’os ?",
  "bonesAndCartilageQ4R1": "Empêcher la déminéralisation avec la vitamine D et C",
  "bonesAndCartilageQ4R2": "Empêcher la déminéralisation avec la vitamine D et K2",
  "bonesAndCartilageQ4R3": "Diminuer l'acidité des tissus",
  "bonesAndCartilageQ5": "Comment diminuer l’inflammation de l’articulation ?",
  "bonesAndCartilageQ5R1": "Avec des antiinflammatoires comme la curcumine",
  "bonesAndCartilageQ5R2": "Avec des produits de glycation antiinflammatoires",
  "bonesAndCartilageQ5R3": "En stimulant la détoxification et l'élimination ",

  "thyroid": "La thyroïde",

  "thyroidQ1": "Qu'est-ce que la thyroïde ?",
  "thyroidQ1R1": "Organe principal de la détoxification",
  "thyroidQ1R2": "Organe principal de production de stress",
  "thyroidQ1R3": "Glande exocrine",
  "thyroidQ1R4": "Glande endocrine",
  "thyroidQ2": "Quelles sont les fonctions de la thyroïde ?",
  "thyroidQ2R1": "Produire les hormones thyroïdiennes T4 et T5",
  "thyroidQ2R2": "Régule la vitesse de dégradation du substrat énergétique",
  "thyroidQ2R3": "Régule le métabolisme energétique",
  "thyroidQ2R4": "Détermine le catabolisme énergétique",
  "thyroidQ3": "Quels sont les composants nécessaires pour la fonction thyroïdienne ?",
  "thyroidQ3R1": "Magnésium",
  "thyroidQ3R2": "Iode",
  "thyroidQ3R3": "Vitamines E et B",
  "thyroidQ3R4": "Vitamine A et oméga 3",
  "thyroidQ3R5": "Zinc",
  "thyroidQ3R6": "Tryptophane",
  "thyroidQ4": "Quel est le rapport entre la thyroïde et les glandes surrénales ?",
  "thyroidQ4R1": "L'activité de la thyroide augmente celle des surrénales",
  "thyroidQ4R2": "L'activité de la thyroide diminue celle des surrénales",
  "thyroidQ4R3": "L'activité de la thyroide stimule le stress",
  "thyroidQ5": "Quel est le rapport entre la thyroïde et le poids du corps ?",
  "thyroidQ5R1": "L'activité de la thyroide fait prendre du poids",
  "thyroidQ5R2": "L'activité de la thyroide fait perdre du poids",
  "thyroidQ5R3": "L'hyperthyroïdie augmente le poids",

  "theSport": "Le sport",

  "theSportQ1": "Comment augmenter son niveau d'énergie pour être plus performant dans la pratique sportive ?",
  "theSportQ1R1": "Avec du coenzyme Q10",
  "theSportQ1R2": "Avec du magnésium",
  "theSportQ1R3": "En augmentant son apport énergétique dans l'alimentation",
  "theSportQ1R4": "Avec de la vitamine C",
  "theSportQ1R5": "Avec les vitamines B2 et  B3",
  "theSportQ2": "Quel est l'importance du cerveau dans la pratique du sport ?",
  "theSportQ2R1": "Centre de commande de la contraction musculaire",
  "theSportQ2R2": "Coordination neuromusculaire",
  "theSportQ2R3": "Inhibe la fatigue",
  "theSportQ3": "Comment optimiser la fonction cardiaque chez le sportif ?",
  "theSportQ3R1": "Avec du coenzyme Q10",
  "theSportQ3R2": "Avec du magnésium",
  "theSportQ3R3": "Avec de la vitamine B2 et B3",
  "theSportQ3R4": "Avec du potassium",
  "theSportQ4": "Quelles sont les différences entre les courbatures, les crampes et les blessures ?",
  "theSportQ4R1": "Les courbatures sont des microblessures, les crampes sont des contractions involontaires et les blessures sont dues à des traumatismes",
  "theSportQ4R2": "Les courbatures sont dues un excès d'acide lactique , les crampes sont des contractions involontaires et les blessures sont dues à des traumatismes",
  "theSportQ4R3": "Les courbatures sont des douleurs dans les muscles et les articulations, les crampes sont des contractions involontaires et les blessures sont dues à des traumatismes",
  "theSportQ4R4": "Aucune des réponses précédentes",
  "theSportQ5": "Comment diminuer les niveaux d'acide lactique chez le sportif pour améliorer la récupération ?",
  "theSportQ5R1": "Augmenter l'élimination par la fonction rénale",
  "theSportQ5R2": "Par le repos",
  "theSportQ5R3": "En stimulant le cycle de cori avec du magnésium",
  "theSportQ5R4": "En augmentant la charge d'entraînement",

  "weightLoss": "La perte de poids",

  "weightLossQ1": "Quels sont les facteurs qui contribuent à la prise de poids ?",
  "weightLossQ1R1": "Une alimentation de mauvaise qualité",
  "weightLossQ1R2": "Le mode de vie sédentaire",
  "weightLossQ1R3": "Une accélération de la thyroide",
  "weightLossQ1R4": "Une accumulation de toxines",
  "weightLossQ1R5": "Une durée de sommeil importante",
  "weightLossQ1R6": "Un stress important",
  "weightLossQ2": "Quelle est la différence entre le tissu adipeux blanc et le tissu adipeux brun ?",
  "weightLossQ2R1": "Le tissu adipeux blanc stocke des toxines alors que le brun stocke du gras",
  "weightLossQ2R2": "Le tissu adipeux blanc stocke du gras alors que le brun brûle des protéines",
  "weightLossQ2R3": "Le tissu adipeux blanc produit des adipokines anti-inflammatoires alors que le brun produit des  adipokines pro-inflammatoires",
  "weightLossQ2R4": "Aucune des réponses précédentes",
  "weightLossQ3": "Quel est le rôle de l'axe intestin cerveau dans la régulation du poids ?",
  "weightLossQ3R1": "L'axe intestin cerveau régule l'appétit",
  "weightLossQ3R2": "L'axe intestin cerveau régule la détoxification",
  "weightLossQ3R3": "L'axe intestin cerveau régule le microbiote",
  "weightLossQ4": "Quels sont les moyens qui permettent la perte de poids en général ?",
  "weightLossQ4R1": "Restriction raisonnable de quantité de l'alimentation",
  "weightLossQ4R2": "Activité physique raisonnable",
  "weightLossQ4R3": "Diminuer la durée du sommeil",
  "weightLossQ4R4": "Diminuer le stress",
  "weightLossQ4R5": "Rééquilibrer le microbiote",
  "weightLossQ4R6": "Diminuer le tissu adipeux brun responsable de l'inflammation",
  "weightLossQ5": "Quels sont les micronutriments qui peuvent être intéressants pour la perte de poids ?",
  "weightLossQ5R1": "La vitamine K",
  "weightLossQ5R2": "Le calcium",
  "weightLossQ5R3": "La L-carnitine",
  "weightLossQ5R4": "Les polyphénols (ex: curcumine)",
  "weightLossQ5R5": "La vitamine du groupe B (B6)",
  "weightLossQ5R6": "Vitamine D",

  "watchVideo": "Voir la video",
  "goToTheDocument": "Voir le document",
  "qualification": "Qualification",
  "sendCertificate": "Envoyer le certificat",
  "validateBySendingTrainingCertification": "Veuillez envoyer votre certificat par email en cliquant sur ce bouton. <1/> Une certification d'une spécialisation équivalente ou supérieure fonctionne également.",
  "certificationSenderButtonNotWorking": "Si le bouton ne fonctionne pas, veuillez envoyer votre certificat par email à info@ags-lab.com",
  "validationButtonActivation": "Le bouton deviendra actif une fois tous les objectifs accomplis",
  "getTheCertification": "Obtenir la certification \"{{name}}\"",
  "displayTheCertification": "Aller à la certification \"{{name}}\"",
  "thePacks": "Les packs",
  "nutritionalPhysiology": "Physiologie nutritionnelle",
  "buyProduct": "Acheter: {{name}}",
  "validateQualification": "Valider la qualification",
  "qualificationEndAt": "Fin de la qualification le {{date}}",
  "directSeller": "Direct-Seller",
  "careerChoice": "Choix de la carrière",
  "chooseTheCareer": "Choisissez la carrière qui vous correspond.",
  "backToCareer": "Retour à la carrière",
  "notAFinalChoiceDescription": "Ce choix n'est pas définitif. La carrière ne sera validée qu'après la réussite de la qualification.",

  //CONSULTANTS
  "mapDisplayError": "Oups, il semblerait que la carte ne charge pas... <1/> Essayez de recharger votre navigateur.",
  "seeTheNutritionConsultant": "Voir le conseiller en nutrition",
  "youNeedToUpdateYourProfile": "Vous devez mettre à jour votre établissement avant de l'afficher en ligne",
  "isCurrentlyYourNutritionConsultant": "{{name}} est actuellement votre consultant en nutrition",
  "visitTheShop": "Entrer dans la boutique",
  "selectLocation": "Séléctionnez un lieu",
  "useCurrentLocation": "Utiliser l'emplacement actuel",
  "currentLocation": "Emplacement actuel",
  "geolocation": "Géolocalisation",

  //ERROR & VALIDATION MESSAGE
  "error": "Erreur",
  "errorMessageAccount": "Il y a une erreur avec votre compte, veuillez nous contacter",
  "pageNotFound": "La page n'existe pas",
  "validatorFieldRequired": "Ce champ est requis",
  "backButtonText": "Retour à la page précédente",
  "permissionDenied": "Vous n'avez pas la permission d'accéder à cette page",
  "errorMessageAnErrorOccurred": "Une erreur est survenue. Veuillez nous contacter pour nous faire part de votre erreur",
  "errorMessageAnotherExists": "{{field}} : ({{value}}) existe déjà sur notre site",
  "errorMessageFieldDoesNotMatch": "{{field}} n'est pas identique",
  "errorMessageFieldNeedToBeLonger": "{{field}} n'est pas assez long",
  "errorMessageFieldNeedToBeShorter": "{{field}} est trop long",
  "errorMessageFieldNeedToBeLarger": "{{field}} doit être plus grand",
  "errorMessageFieldNeedToBeLess": "{{field}} doit être plus petit",
  "errorMessageEmptyField": "{{field}} ne peut pas être vide",
  "errorMessageFieldNeedToBeANumber": "{{field}} doit être de type numérique",
  "errorMessageMustBeAValidEmail": "{{value}} n'est pas un email valide",
  "errorMessageMustBeAValidPhoneNumber": "{{value}} n'est pas un numéro de téléphone valide",
  "errorMessageProCode": "Aucun professionnel n'est associé à ce code",
  "errorMessageAlreadyTaken": "{{value}} existe déjà sur le site",
  "errorMessageCannotBeChanged": "{{field}} ne peut pas être modifié",
  "errorMessageIsRequired": "Le champ \"{{field}}\" est obligatoire",
  "errorMessageIncorrectEmailOrPassword": "L'email ou le mot de passe est incorrect",
  "errorMessageInvalidConsultantCode": "L'identifiant du conseiller n'est pas valide",
  "errorMessageInvalidConsultantLink": "L'identifiant du conseiller n'est pas valide",
  "errorMessageRequiredFields": "Une ou plusieurs entrées sont vides ou incorrectes. Veuillez vérifier les champs requis et recommencer",
  "errorMessageRequiredBirthDate": "La date de naissance est requise",
  "errorMessageNutritionConsultantCodeDoesNotExist": "L'identifiant du conseiller en nutrition n'existe pas",
  "errorMessageNetworkError": "Le site rencontre des problèmes pour communiquer avec le serveur. Veuillez vérifier votre connexion à internet et réésayer",
  "errorMessageFieldNotAlphaNum": "Le champ \"{{field}}\" n'est pas de type alpha-numérique",
  "errorMessageFieldNotMatchPattern": "Le champ \"{{field}}\" ne correspond pas au modèle requis",
  "errorMessageNotFound": "Élément non trouvé",
  "errorMessageArticleNotFound": "L'article non trouvé",
  "errorMessageArticleDoesNotExistInLanguage": "L'article n'existe pas dans cette langue",
  "validatorInvalidEmail": "Email invalide",
  "validatorInvalidDate": "Date invalide",
  "dataUpdated": "Données mises à jour",
  "homepage": "Page d'accueil",
  "pageNotFoundDescription1": "La page que vous recherchez n'est pas disponible pour le moment",
  "pageNotFoundDescription2": "Vous pouvez revenir à la page d'accueil ou contacter notre équipe",
  "invalidAccessToken": "Votre jeton d'accès n'est plus valide, veuillez vous reconnecter.",
  "errorMessageUnauthorized": "Vous n'avez pas les autorisations nécessaires pour effectuer cette action.",
  "errorVATNumber": "Le numéro de TVA est invalide.",
  "errorMessageCompleteBankDetails": "Vous devez compléter vos coordonnées bancaires avant d'effectuer un virement",
  "errorMessageNotEnoughMoneyIntoWallet": "Le montant de votre cagnotte n'est pas suffisant pour effectuer cette action",
  "needMembership": "Vous devez être membre pour accéder à cette page.",
  "errorMessageGiftCardAmount": "Le montant de la carte-cadeau doit être supérieur au montant du paiement",
  "errorMessageGiftCardMalformed": "Le code de la carte cadeau doit être du type : XXX-XXXXXX",
  "errorMessageGiftCardNotFound": "La carte cadeau n'existe pas ou a déjà été utilisée",
  "errorMessageGiftCardAlreadyChosen": "La carte cadeau a déjà été choisie",

  //SNACKBAR
  "open": "Ouvrir",
  "close": "Fermer",
  "thisActionIsIrreversible": "Cette action est irréversible",

  //CONTACT
  "contact": "Contact",
  "contactTitle": "Contact",
  "contactUsTitle": "Contactez-nous",
  "contactUsFirstName": "Prénom",
  "contactUsLastName": "Nom",
  "contactUsPhone": "Téléphone",
  "contactUsEmail": "Email",
  "contactUsMessage": "Message",
  "contactSendMessageButton": "Envoyer le message",
  "message": "Message",
  "messageSent": "Votre message a bien été transmis à nos équipes, nous vous répondrons dans les plus brefs délais",
  "subject": "Sujet",
  "whoToContact": "Qui contacter ?",
  "theNutri-logicsTeam": "L'équipe d'Nutri-logics",
  "yourNutritionConsultant": "Votre conseiller en nutrition",

  //FOOTER
  "homeLink": "Accueil",
  "followUs": "Suivez-nous",
  "help": "Aide",
  "termsOfSales": "Conditions générales de ventes",
  "networkTermsAndConditions": "Conditions générales réseau",

  //BLACK FRIDAY
  "blackFridayMessage": "Green Friday: du 26 au 28, les packs Nutri-logics à -20%",
  "blackFridayButton": "Voir les packs",

  //CHATGPT
  "Email": "Email",
  "proName": "Nom et prénom de votre thérapeute",
  "Subscribe": "S'inscrire",
  "Enter your email": "Entrez votre email",
  "SubscribeToOurNewsletter": "Inscrivez-vous à notre newsletter",
  "We have high standards for email too": "Vous cherchez une source d’information fiable sur les dernières tendances en matière de santé de bien-être ?",
  "day": "jour",
  "year": "année",
  "month": "mois",
  "mailingAnduserLanguage": "Langue des emails",
  "alreadyRegistered": "Déjà inscrit ?",
  "proCode": "Code d’inscription",
  "modify": "Modifier",
  "metagenicsNotAllowed": "Le laboratoire Métagenics ne distribue pas ce complément alimentaire sur le territoire français.\n" + "Nous vous invitons à contacter votre thérapeute pour trouver l'alternative la plus adaptée",
  "healthPro": "Professionnel de la santé",
  "aboutUs": "À propos",
};
